import { DateTime } from 'luxon';

const WEATHER_DATA_KEY = 'PATITHIN_WEATHER_DATA';
const WEATHER_DATA_KEY_FORMAT = 'yyyy-MM-dd-HH';
const LANG_KEY = 'PATITHIN_LANG';
const DEFAULT_LANG = 'th';
const SUPPORTED_LANGS = ['en', 'th'];
const DEFAULT_CITY = 'bangkok';

const dateFormatForLocale = ({ lang, date, format }) => {
  if (lang === 'th') {
    return date.reconfigure({ outputCalendar: 'buddhist' }).setLocale('th-TH').toFormat(format);
  }
  return date.toFormat(format);
};

const launchedAsApp = () => {
  if(window.navigator.standalone === true){
    return true;
  }
  return false;
}

const getEcosystem = () => {
  let ecosystem = 'Other';
  if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i)) || (navigator.userAgent.match(/Mac/i))) {
    ecosystem = 'Apple';
  } else if (navigator.userAgent.match(/Android/i)) {
    ecosystem = 'Android';
  }
  return ecosystem;
}

const getStoredLangPreference = () => {
  if (window && localStorage) {
    const storedLang = localStorage.getItem(LANG_KEY);
    if (SUPPORTED_LANGS.includes(storedLang)) {
      return storedLang
    }
  }
  return DEFAULT_LANG;
}

const storeLangPreference = (lang) => {
  if (window && localStorage) {
    if (SUPPORTED_LANGS.includes(lang)) {
      localStorage.setItem(LANG_KEY, lang);
      return lang;
    }
  }
  return getStoredLangPreference();
}

const getWeatherData = () => {
  return new Promise(async (resolve, reject) => {
    const today = DateTime.local();
    const todayKey = today.toFormat(WEATHER_DATA_KEY_FORMAT);
    let city = DEFAULT_CITY;
    let region;
    try {
      const cityData = await getCurrentCity();
      city = cityData.city;
      region = cityData.region;
      console.log('Got current city', city, region);
    } catch(e) {
      console.error(`Got error identifying location, defaulting to ${city}`);
    }
    
    if (window && localStorage) {
      const cachedWeatherData = localStorage.getItem(WEATHER_DATA_KEY);
      if (cachedWeatherData) {
        try {
          const weatherData = JSON.parse(cachedWeatherData);
          const cityWeatherData = weatherData[city];
          const todayWeatherData = cityWeatherData && cityWeatherData[todayKey];

          if (todayWeatherData) {
            console.log('Got weather data from cache, returning cached data');
            return resolve(todayWeatherData);
          }
        } catch (e) {
          console.error('Error parsing localstorage data, reverting to fetching fresh version', e);
        }
      }
    }
    fetch(`https://weather.patithin.com/?city=${city}&region=${region}`)
      .then(response => response.json())
      .then((data) => {
        console.log('Got weather data, storing in cache');
        if (window && localStorage) {
          try {
            const weatherData = {};
            const cityWeatherData = {};
            cityWeatherData[todayKey] = data.weather;
            weatherData[city] = cityWeatherData;

            const stringifiedData = JSON.stringify(weatherData);
            localStorage.setItem(WEATHER_DATA_KEY, stringifiedData);
            console.log('Storage hydrated with fresh weather data');
          } catch (e) {
            console.error('Error stringifying weather data', e)
          }          
        }
        return resolve(data.weather);
      })
      .catch((error) => {
        console.error('Error fetching weather data', error);
        reject(error);
      });
  });
};

const getConcentrationRange = (pm2p5) => {
  if (pm2p5 >= 0 && pm2p5 <= 12) {
    return {
      clow: 0,
      chigh: 12,
      ilow: 0,
      ihigh: 50,
    };
  } else if (pm2p5 > 12 && pm2p5 <= 35.4) {
    return {
      clow: 12.1,
      chigh: 35.4,
      ilow: 51,
      ihigh: 100,
    };
  } else if (pm2p5 > 35.4 && pm2p5 <= 55.4) {
    return {
      clow: 35.5,
      chigh: 55.4,
      ilow: 101,
      ihigh: 150,
    };
  } else if (pm2p5 > 55.4 && pm2p5 <= 150.4) {
    return {
      clow: 55.5,
      chigh: 150.4,
      ilow: 151,
      ihigh: 200,
    };
  }  else if (pm2p5 > 150.4 && pm2p5 <= 250.4) {
    return {
      clow: 150.5,
      chigh: 250.4,
      ilow: 201,
      ihigh: 300,
    };
  }  else if (pm2p5 > 250.4 && pm2p5 <= 350.4) {
    return {
      clow: 250.5,
      chigh: 350.4,
      ilow: 301,
      ihigh: 400,
    };
  }  else if (pm2p5 > 350.4) {
    return {
      clow: 350.5,
      chigh: 500.4,
      ilow: 401,
      ihigh: 500,
    };
  }
}

const calculateUSQIFromPM2p5 = (pm2p5) => {
  const {
    clow,
    chigh,
    ilow,
    ihigh
   } = getConcentrationRange(pm2p5);
  const usaqi = Math.round((((ihigh - ilow) / (chigh - clow)) * (pm2p5 - clow)) + ilow);
  return usaqi;
};

const getKeyForCity = (city) => {
  return city.toLowerCase().replace(/\s/g, '');
}

const getCurrentCity = () => {
  return new Promise((resolve) => {
    let url = 'https://pro.ip-api.com/json?key=678csvMB3GJrTgB';
    let regionKeyName = 'regionName';
    // const now = DateTime.local();
    // if (now.year > 2020) {
    //   url = 'http://ip-api.com/json/';
    //   regionKeyName = 'regionName';
    // }
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        const cityKey = getKeyForCity(data.city);
        const regionKey = getKeyForCity(data[regionKeyName]);
        console.log('Got city', cityKey, data);
        resolve({ city: cityKey, region: regionKey });
      })
      .catch((e) => {
        console.error('Failed to get city, returning default city', e);
        resolve(DEFAULT_CITY);
      });    
  });
};

export {
  dateFormatForLocale,
  launchedAsApp,
  getEcosystem,
  getWeatherData,
  getStoredLangPreference,
  storeLangPreference,
  calculateUSQIFromPM2p5,
  getCurrentCity,
}
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTime } from 'luxon';
import { dateFormatForLocale, getWeatherData, calculateUSQIFromPM2p5 } from "../../utils";

import Block from '../../components/Block';

import { ReactComponent as AQIModerate } from "../../assets/weather/aqi-moderate.svg";
import { ReactComponent as AQIGood } from "../../assets/weather/aqi-good.svg";
import { ReactComponent as AQIUnhealthy } from "../../assets/weather/aqi-unhealthy.svg";
import { ReactComponent as AQIBad } from "../../assets/weather/aqi-verybad.svg";
import { ReactComponent as AQIVeryUnhealthy } from "../../assets/weather/aqi-veryunhealthy.svg";
import { ReactComponent as AQIHazard } from "../../assets/weather/aqi-hazard.svg";

import suncloud from "../../assets/weather/suncloud.svg";
import cloudrain from "../../assets/weather/cloudrain.svg";
import sun from "../../assets/weather/sun.svg";
import rainthunder from "../../assets/weather/rainthunder.svg";
import moon from "../../assets/weather/moon.svg";
import mooncloud from "../../assets/weather/mooncloud.svg";

import styles from './WeatherBlock.module.css';

const getAQIIcon = (aqi) => {
  if (aqi > 0 && aqi <= 50) {
    return <AQIGood width="64px" height="64px" />;
  } else if (aqi > 50 && aqi <= 100) {
    return <AQIModerate width="64px" height="64px" />;
  } else if (aqi > 100 && aqi <= 150) {
    return <AQIUnhealthy width="64px" height="64px" />;
  } else if (aqi > 150 && aqi <= 200) {
    return <AQIBad width="64px" height="64px" />;
  } else if (aqi > 200 && aqi <= 300) {
    return <AQIVeryUnhealthy width="64px" height="64px" />;
  } else if (aqi > 300 && aqi <= 400) {
    return <AQIHazard width="64px" height="64px" />;
  } else if (aqi > 400) {
    return <AQIHazard width="64px" height="64px" />;
  }
}

const getAQIDescription = (aqi) => {
  if (aqi > 0 && aqi <= 50) {
    return 'weather_aqi_good_description';
  } else if (aqi > 50 && aqi <= 100) {
    return 'weather_aqi_moderate_description';
  } else if (aqi > 100 && aqi <= 150) {
    return 'weather_aqi_unhealthy_description';
  } else if (aqi > 150 && aqi <= 200) {
    return 'weather_aqi_bad_description';
  } else if (aqi > 200 && aqi <= 300) {
    return 'weather_aqi_very_unhealthy_description';
  } else if (aqi > 300 && aqi <= 400) {
    return 'weather_aqi_hazardous_description';
  } else if (aqi > 400) {
    return 'weather_aqi_hazardous_description';
  }
}

const getAQITitle = (aqi) => {
  if (aqi > 0 && aqi <= 50) {
    return 'weather_aqi_good';
  } else if (aqi > 50 && aqi <= 100) {
    return 'weather_aqi_moderate';
  } else if (aqi > 100 && aqi <= 150) {
    return 'weather_aqi_unhealthy';
  } else if (aqi > 150 && aqi <= 200) {
    return 'weather_aqi_bad';
  } else if (aqi > 200 && aqi <= 300) {
    return 'weather_aqi_very_unhealthy';
  } else if (aqi > 300 && aqi <= 400) {
    return 'weather_aqi_hazardous';
  } else if (aqi > 400) {
    return 'weather_aqi_hazardous';
  }
}

const getWeatherIconForWeather = (weather) => {
  const isNight = DateTime.local().hour >= 19;
  switch (weather.weather.icon_code) {
    case 'suncloud':
      if (isNight) {
        return mooncloud;
      }
      return suncloud;
    case 'cloudrain':
      return cloudrain;
    case 'sun':
      if (isNight) {
        return moon;
      }
      return sun;
    case 'rainthunder':
      return rainthunder;  
    default:
      return suncloud;
  }
}

const WeatherData = ({ weather, t }) => {
  const usaqi = weather.aqi.aqius ? weather.aqi.aqius : calculateUSQIFromPM2p5(weather.aqi.pm2_5);
  return (
    <>
      <div className="columns is-mobile">
        <div className="column is-narrow p-0">
          <figure className="image is-96x96">
            <img className="image" src={getWeatherIconForWeather(weather)} alt="weathericon" />
          </figure>        
        </div>
        <div className="column">
          <p className={`title has-text-weight-bold is-5 ${styles.text}`}>{weather.weather.temp_in_cel}˚C <span className={`subtitle is-6 ${styles.text} is-pulled-right`}>H: {weather.weather.high_temp_in_cel}˚ L: {weather.weather.low_temp_in_cel}˚</span></p>        
          <p className={`title has-text-weight-bold subtitle is-5 ${styles.text}`}>{t(weather.weather.description_code, weather.weather.metadata)}</p>
        </div>   
      </div>
      <div className="columns is-mobile">
        <div className="column is-narrow p-0">
          <div className="is-flex is-justify-content-center is-align-items-center" style={{width: '96px', height: '96px'}}>
            {getAQIIcon(usaqi)}
          </div>        
        </div>
        <div className="column">
          <p className={`title has-text-weight-bold is-5 ${styles.text}`}>US AQI: {usaqi}<span>({t(getAQITitle(usaqi))})</span></p>        
          <p className={`title has-text-weight-bold subtitle is-5 ${styles.text}`}>{t(getAQIDescription(usaqi))}</p>
        </div>   
      </div>   
    </>
  );
}

const WeatherBlock = ({ today }) => { 
  const { t, i18n } = useTranslation();
  const [weather, setWeather] = useState();

  useEffect(() => {
    getWeatherData()
      .then((data) => {
        console.log('Got weather info', data);
        setWeather(data);        
      });
  }, []);
  return (
    <Block>
      <div className="columns">
        <div className="column">
          <p className={`subtitle is-4 mb-0 ${styles.text}`}>{t('weather_title')}</p>
          <p className={`subtitle is-5 mb-4 ${styles.text}`}>{`${dateFormatForLocale({lang: i18n.language, date: today, format: 'DD'})}`}</p>
        </div>
      </div>
      {weather && <WeatherData weather={weather} t={t} />}
    </Block>
  );
}

export default WeatherBlock;

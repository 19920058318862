const quotes = [
  {
    author: 'Walt Disney',
      quote: {
      en: 'All our dreams can come true, if we have the courage to pursue them.',
      th: 'ทุกความฝันสามารถเป็นจริงได้ ถ้าเรากล้าพอที่จะไล่ตามความฝันนั้น',
    },
  },
  {
    author: 'Robert H. Schuller',
      quote: {
      en: 'Tough times never last, but tough people do.',
      th: 'เวลาที่ยากลำบากจะอยู่กับเราไม่นาน แต่คนที่แข็งแกร่งต่างหากที่จะอยู่ได้นาน',
    },
  },
  {
    author: 'Oprah Winfrey',
      quote: {
      en: 'Don’t worry about being successful but work toward being significant and the success will naturally follow.',
      th: 'อย่าไปคิดกังวลว่าทำยังไงถึงจะประสบความสำเร็จ แต่จงมุ่งมั่นทำงานให้เกิดผลแล้วความสำเร็จก็จะตามมาเอง',
    },
  },
  {
    author: 'Walt Disney',
      quote: {
      en: 'The way to get started is to quit talking and begin doing.',
      th: 'วิธีที่จะเริ่มต้นได้นั้น คือ หยุดพูดและลงมือทำซะ',
    },
  },
  {
    author: 'John A. Shedd',
      quote: {
      en: 'A ship in the harbor is safe, but that is not what ships are built for.',
      th: 'เรือที่ปลอดภัยคือเรือที่จอดเทียบท่า แต่นั่นไม่ใช่จุดประสงค์ที่เรือถูกสร้างขึ้นมา',
    },
  },
  {
    author: 'Drew Houston',
      quote: {
      en: 'Don’t worry about failure; you only have to be right once.',
      th: 'จะต้องมีสักครั้งที่คุณประสบความสำเร็จ ดังนั้นอย่าไปกังวลกับความล้มเหลว',
    },
  },
  {
    author: 'Thomas Edison',
      quote: {
      en: 'I have not failed. I’ve just found 10,000 ways that won’t work.',
      th: 'ฉันไม่ได้ล้มเหลว ฉันแค่ค้นพบหมื่นวิธีที่มันไม่ได้ผล',
    },
  },
  {
    author: 'Warren Buffett',
      quote: {
      en: 'Someone is sitting in the shade today because someone planted a tree a long time ago.',
      th: 'ที่เรานั่งอยู่ใต้ร่มไม้ทุกวันนี้ได้ เพราะมีใครบางคนได้ปลูกต้นไม้ไว้เมื่อนานมาแล้ว',
    },
  },
  {
    author: 'Warren Buffett',
      quote: {
      en: 'It takes 20 years to build a reputation and five minutes to ruin it. If you think about that, you’ll do things differently.',
      th: 'อาจจะต้องใช้เวลาถึง 20 ปีในการสร้างชื่อเสียง แต่ใช้เวลาเพียง 5 นาทีในการทำลายมันลง ถ้าคุณคิดแบบนี้ การกระทำของคุณก็จะเปลี่ยนไปอย่างสิ้นเชิง',
    },
  },
  {
    author: 'Tony Hsieh',
    quote: {
      en: 'Chase the vision, not the money, the money will end up following you.',
      th: 'จงวิ่งไล่ตามวิสัยทัศน์ ไม่ใช่เงิน แล้วเงินจะไล่ตามคุณเอง',
    },
  },
  {
    author: 'Warren Buffett',
    quote: {
      en: 'Be fearful when others are greedy. Be greedy when others are fearful.',
      th: 'จงกลัวในเวลาที่คนอื่นกำลังโลภ จงโลภในเวลาที่คนอื่นกำลังกลัว',
    },
  },
  {
    author: 'Reid Hoffman',
    quote: {
      en: 'No matter how brilliant your mind or strategy, if you’re playing a solo game, you’ll always lose out to a team.',
      th: 'ไม่ว่าคุณจะฉลาดหรือมีกลยุทธ์ที่ยอดเยี่ยมแค่ไหนก็ตาม หากคุณเล่นเกมคนเดียว คุณจะแพ้คนที่เล่นเป็นทีมเสมอ',
    },
  },
  {
    author: 'Albert Einstein',
    quote: {
      en: 'Great spirits have always encountered violent opposition from mediocre minds.',
      th: 'คนที่มีจิตใจที่ยิ่งใหญ่มักพบกับการต่อต้านอย่างรุนแรงจากคนที่มีความคิดปานกลาง',
    },
  },
  {
    author: 'Bundit Ungrangsee',
    quote: {
      en: 'No risk, No reward.',
      th: 'ไร้ความเสี่ยง ไร้รางวัลตอบแทน',
    },
  },
  {
    author: 'William Shakespeare',
    quote: {
      en: 'Speak less than you know, have more than you show.',
      th: 'พูดให้น้อยกว่าที่คุณรู้ มีดีให้มากกว่าที่คนเห็น',
    },
  },
  {
    author: 'Napoleon Hill',
    quote: {
      en: 'Patience, persistence and perspiration make an unbeatable combination for success.',
      th: 'หยาดเหงื่อ ความอดทน และความพยายาม คือส่วนผสมชั้นเลิศของความสำเร็จ',
    },
  },
  {
    author: 'Malcolm S. Forbes',
    quote: {
      en: 'Victory is sweetest when you’ve known defeat.',
      th: 'ชัยชนะจะหอมหวานที่สุดเมื่อคุณรู้จักความพ่ายแพ้',
    },
  },
  {
    author: 'Abraham Lincoln',
    quote: {
      en: 'If I had eight hours to chop down a tree, I’d spend six hours sharpening my ax.',
      th: 'ถ้าผมมีเวลา 8 ชั่วโมงในการตัดต้นไม้ ผมจะใช้เวลา 6 ชั่วโมงในการลับขวานของผม',
    },
  },
  {
    author: 'Winston Churchill',
    quote: {
      en: 'Success is not final, failure is not fatal, it is the courage to continue that counts.',
      th: 'ความสำเร็จไม่ใช่จุดสิ้นสุด ความล้มเหลวก็ไม่ถึงกับตาย มันคือความกล้าหาญที่จะก้าวต่อไป',
    },
  },
  {
    author: 'Jim Rohn',
    quote: {
      en: 'Successful people do what unsuccessful people are not willing to do. Don’t wish it were easier; wish you were better.',
      th: 'คนที่ประสบความสำเร็จมักจะทำในสิ่งที่คนไม่ประสบความสำเร็จไม่ทำ อย่าหวังว่ามันจะเป็นเรื่องง่าย แต่ให้หวังว่าคุณจะดีขึ้นกว่าเดิม',
    },
  },
  {
    author: 'Bethany Hamilton',
    quote: {
      en: 'Courage doesn’t mean you don’t get afraid. Courage means you don’t let fear stop you.',
      th: 'ความกล้า ไม่ได้หมายความว่าคุณไม่กลัว แต่ความกล้าหมายความว่าคุณไม่ให้ความกลัวมาหยุดคุณ',
    },
  },
  {
    author: 'Steve Jobs',
    quote: {
      en: 'If you really look closely, most overnight successes took a long time.',
      th: 'หากคุณมองให้ดี ความสำเร็จชั่วข้ามคืน ส่วนใหญ่มักใช้เวลานาน',
    },
  },
  {
    author: 'Brian Tracy',
    quote: {
      en: 'Spend eighty percent of your time focusing on the opportunities of tomorrow rather than the problems of yesterday.',
      th: 'ใช้เวลา 80% ของคุณเพื่อโฟกัสกับโอกาสในวันพรุ่งนี้ดีว่าจมอยู่กับปัญหาของเมื่อวาน',
    },
  },
  {
    author: 'Benjamin Disraeli',
    quote: {
      en: 'The secret of success in life is for a man to be ready for his opportunity when it comes.',
      th: 'เคล็ดลับของความสำเร็จในชีวิต คือ ความพร้อมรับมือกับโอกาสที่เข้ามา',
    },
  },
  {
    author: 'Usain Bolt',
    quote: {
      en: 'There are better starters than me but I’m a strong finisher.',
      th: 'มีผู้เริ่มต้นที่ดีกว่าผม แต่ผมเป็นผู้พิชิตที่แข็งแกร่ง',
    },
  },
  {
    author: 'Napoleon Hill',
    quote: {
      en: 'Strength and growth come only through continuous effort and struggle.',
      th: 'ความแข็งแกร่งและการเติบโต จะเกิดขึ้นเมื่อเราได้ใช้ความพยายามและการดิ้นรนอย่างต่อเนื่อง',
    },
  },
  {
    author: 'Helen Rowland',
    quote: {
      en: 'You will never win if you never begin.',
      th: 'คุณไม่มีทางสำเร็จได้ ถ้าคุณไม่เริ่มต้น',
    },
  },
  {
    author: 'Henry F. Amiel',
    quote: {
      en: 'Almost everything comes from nothing.',
      th: 'เกือบทุกสิ่งบนโลกใบนี้ ล้วนมาจากความไม่มีเหมือนกันทั้งนั้น',
    },
  },
  {
    author: 'Ben Stein',
    quote: {
      en: 'The indispensable first step to getting the things you want out of life is this: Decide what you want.',
      th: 'ก้าวแรกที่สำคัญในการได้มาซึ่งสิ่งที่ต้องการในชีวิตก็คือ ถามตัวเองก่อนว่าคุณต้องการอะไร',
    },
  },
  {
    author: 'Alan Cohen',
    quote: {
      en: 'Do not wait until the conditions are perfect to begin. Beginning makes the conditions perfect.',
      th: 'อย่ารอจนกว่าปัจจัยต่าง ๆ จะพร้อมสำหรับการเริ่มต้นเพราะการเริ่มต้นต่างหากที่จะทำให้ปัจจัยทุกอย่างพร้อมเอง',
    },
  },
  {
    author: 'Ralph Blum',
    quote: {
      en: 'Nothing is predestined. The obstacles of your past can become the gateways that lead to new beginnings.',
      th: 'ไม่มีอะไรกำหนดได้ล่วงหน้า อุปสรรคในอดีตของคุณอาจกลายเป็นหนทางที่นำไปสู่การเริ่มต้นใหม่',
    },
  },
  {
    author: 'George Bernard Shaw',
    quote: {
      en: 'Life isn’t about finding yourself. Life is about creating yourself.',
      th: 'ชีวิตไม่ใช่การค้นหาตัวเอง แต่มันคือการสร้างตัวของตัวเองขึ้นมาต่างหาก',
    },
  },
  {
    author: 'Mulan',
    quote: {
      en: 'The flower that blooms in adversity is the rarest and most beautiful of all.',
      th: 'ดอกไม้ที่เบ่งบานท่ามกลางสภาพแวดล้อมที่เลวร้าย ย่อมเป็นดอกไม้ที่หายากและสวยงามที่สุดเมื่อเทียบกับดอกไม้อื่นๆ',
    },
  },
  {
    author: 'Ernest Hemingway',
    quote: {
      en: 'The world breaks everyone, and afterward, some are strong at the broken places.',
      th: 'โลกมันก็โหดร้ายกับทุกคนแหละ พอผ่านไปได้ บางคนก็เข้มแข็งจากเหตุการณ์แย่ๆ พวกนั้นแหละ',
    },
  },
  {
    author: 'Joel Osteen',
    quote: {
      en: 'Make up your mind that no matter what comes your way, no matter how difficult, no matter how unfair, you will do more than simply survive. You will thrive in spite of it.',
      th: 'จำใส่ใจไว้เสมอ ไม่ว่าอะไรก็ตามที่ถาโถมเข้ามาในชีวิตคุณ แม้ว่ามันจะยากขนาดไหน ถึงมันอาจจะไม่ยุติธรรมเพียงใดก็ตาม สิ่งที่คุณจะทำนั้นมากกว่าแค่การเอาตัวเองให้รอด  คุณจะเติบโตผ่านความยากลำบากนั้น',
    },
  },
  {
    author: 'Friedrich Nietzsche',
    quote: {
      en: 'That which does not kill us makes us stronger.',
      th: 'อะไรก็ตามที่ฆ่าเราไม่ตาย นั่นแหละจะทำให้เราแข็งแกร่งขึ้น',
    },
  },
  {
    author: 'Vivian Greene',
    quote: {
      en: 'Life is not about waiting for a storm to pass. It’s learning to dance in the rain.',
      th: 'ชีวิตไม่ใช่แค่การอดทนและรอให้พายุร้ายผ่านพัดไป แต่คือการเรียนรู้ที่จะเต้นรำท่ามกลางสายฝนพรำต่างหาก',
    },
  },
  {
    author: 'Demi Lovato',
    quote: {
      en: 'Nothing is more beautiful than the smile that has struggled through the tears.',
      th: 'ไม่มีอะไรที่งดงามไปมากกว่าการยิ้มสู้ทั้งน้ำตา',
    },
  },
  {
    author: 'Johann Wolfgang von Goethe',
    quote: {
      en: 'What is not started today is never finished tomorrow.',
      th: 'สิ่งที่ไม่ได้เริ่มต้นในวันนี้ ก็จะไม่มีทางสำเร็จในวันพรุ่งนี้เช่นกัน',
    },
  },
  {
    author: 'Henry Ford',
    quote: {
      en: 'Failure is the opportunity to begin again more intelligently.',
      th: 'ความล้มเหลวเป็นโอกาสที่จะเริ่มต้นอีกครั้ง แต่เป็นการเริ่มต้นอย่างฉลาดกว่าเดิม',
    },
  },
  {
    author: 'Maria Robinson',
    quote: {
      en: 'Nobody can go back and start a new beginning, but anyone can start today and make a new ending.',
      th: 'ไม่มีใครย้อนเวลาและกลับไปตอนเริ่มต้นได้ แต่ทุกคนสามารถเริ่มต้นและกำหนดจุดสิ้นสุดจุดใหม่ได้',
    },
  },
  {
    author: 'Frank Herbert',
    quote: {
      en: 'The beginning of knowledge is the discovery of something we do not understand.',
      th: 'จุดเริ่มต้นของความรู้ คือการค้นพบบางสิ่งที่เราไม่เข้าใจ',
    },
  },
  {
    author: 'Frederick R. Bliss',
    quote: {
      en: 'Don\'t let your past decide your future.',
      th: 'อย่าปล่อยให้อดีตมาตัดสินอนาคต',
    },
  },
  {
    author: 'Pablo Picasso',
    quote: {
      en: 'Action is the foundational key to all success.',
      th: 'การลงมือทำคือกุญแจสู่ความสำเร็จ',
    },
  },
  {
    author: 'Abu Bakr',
    quote: {
      en: 'Knowledge without action is meaningless.',
      th: 'ความรู้ที่ปราศจากการลงมือทำย่อมไร้ความหมาย',
    },
  },
  {
    author: 'Marriage Story',
    quote: {
      en: 'I\'ll never stop loving him, even though it doesn\'t make sense anymore.',
      th: 'ฉันจะไม่มีวันหยุดรักเขาเลย แม้มันจะดูไม่สมเหตุสมผลแล้วก็ตาม',
    },
  },
  {
    author: 'Ray Bradbury',
    quote: {
      en: 'Love is the answer to everything. It\'s the only reason to do anything.',
      th: 'รัก คือคำตอบของทุกอย่าง เป็นเหตุผลเดียวในการกระทำ',
    },
  },
  {
    author: 'Nicholas Sparks',
    quote: {
      en: 'Love is like the wind, you can\'t see it, but you can feel it.',
      th: 'ความรักก็เหมือนกับสายลม คุณมองไม่เห็นได้ด้วยตา แต่สัมผัสได้ด้วยใจ',
    },
  },
  {
    author: 'Martin Luther King, Jr',
    quote: {
      en: 'Darkness cannot drive out darkness: only light can do that. Hate cannot drive out hate: only love can do that.',
      th: 'ความมืดไม่สามารถหายไปได้ด้วยความมืด มีเพียงแสงสว่างเท่านั้นที่ทำได้ ความเกลียดไม่สามารถหายไปได้ด้วยความเกลียด ความรักเท่านั้นที่ทำได้',
    },
  },
  {
    author: 'Orson Wells',
    quote: {
      en: 'We\'re born alone, we live alone, we die alone. Only through our love and friendship can we create the illusion for the moment that we\'re not alone.',
      th: 'เราเกิดมาคนเดียว ใช้ชีวิตคนเดียว และตายไปเพียงคนเดียว มีแค่ความรักและมิตรภาพของเราเท่านั้นที่สามารถสร้างภาพลวงตาว่าเราไม่ได้เดียวดาย',
    },
  },
  {
    author: 'John D. Rockefeller Jr.',
    quote: {
      en: 'The secret of success is to do the common thing uncommonly well.',
      th: 'เคล็ดลับของความสำเร็จคือ การทำสิ่งธรรมดาให้ดีแบบไม่ธรรมดา',
    },
  },
  {
    author: 'Barack Obama',
    quote: {
      en: 'Change will not come if we wait for some other person or some other time.',
      th: 'การเปลี่ยนแปลงจะไม่มีทางเกิดขึ้น ถ้าเรามัวแต่รอคนอื่นหรือรอเวลา',
    },
  },
  {
    author: 'Bill Gates',
    quote: {
      en: 'Success is a lousy teacher. It seduces smart people into thinking they can’t lose.',
      th: 'ความสำเร็จเป็นครูที่แย่ มันทำให้คนฉลาดคิดไปเองว่าพวกเขาไม่มีวันแพ้',
    },
  },
  {
    author: 'Bundit Ungrangsee',
    quote: {
      en: 'There are no “great” persons, only ordinary persons who dream of great things and stop at nothing to achieve them.',
      th: 'ไม่มีคนที่ยิ่งใหญ่หรอก มีแต่คนธรรมดาที่ฝันถึงสิ่งยิ่งใหญ่ และไม่หยุดจนกว่าจะได้มันมา',
    },
  },
  {
    author: 'Michelangelo',
    quote: {
      en: 'If people knew how hard I worked to get my mastery, it wouldn’t seem so wonderful at all.',
      th: 'ถ้าทุกคนรู้ว่าผมทำงานหนักแค่ไหนกว่าจะเชี่ยวชาญได้แบบนี้ คุณจะไม่แปลกใจเลย',
    },
  },
  {
    author: 'Barack Obama',
    quote: {
      en: 'If you’re walking down the right path and you’re willing to keep walking, eventually you’ll make progress.',
      th: 'ถ้าคุณกำลังเดินไปตามทางที่ถูกต้อง และยังคงเดินต่อไปเรื่อยๆ ในที่สุดคุณก็จะก้าวหน้าไปเรื่อยๆ'
    }
  },
  {
    author: 'Barack Obama',
    quote: {
      en:  'Nothing in life that’s worth anything is easy.',
      th: 'ไม่มีสิ่งมีค่าไหนในชีวิตเป็นเรื่องง่าย'
    }
  },
  {
    author: 'Steve Jobs',
    quote: {
      en: 'Don’t let the noise of others’ opinions drown out your own inner voice.',
      th: 'อย่าปล่อยให้เสียงของคนอื่นมาทำลายความเชื่อมั่นในตัวเอง'
    }
  },
  {
    author: 'Steve Jobs',
    quote: {
      en: 'Your time is limited, so don’t waste it living someone else’s life.',
      th: 'เวลาของคุณมีจำกัด ดังนั้นอย่าเสียเวลาไปกับชีวิตในแบบของคนอื่น'
    }
  },
  // {
  //   author: 'Steve Jobs',
  //   quote: {
  //     en: 'Stay Hungry, Stay Foolish.',
  //     th: 'จงทำตัวให้หิวและโง่อยู่ตลอดเวลา'
  //   }
  // },
  {
    author: 'Coco Chanel',
    quote: {
      en: 'Success is most often achieved by those who don’t know that failure is inevitable.',
      th: 'ความสำเร็จส่วนใหญ่มักเกิดขึ้นกับคนที่ไม่รู้ว่าความล้มเหลวเป็นสิ่งที่หลีกเลี่ยงไม่ได้'
    }
  },
  {
    author: 'Mahatma Gandhi',
    quote: {
      en: 'Be the change you wish to see in the world.',
      th: 'จงเปลี่ยนแปลงในแบบที่คุณต้องการให้โลกเป็น'
    }
  },
  {
    author: 'Steve Martin',
    quote: {
      en: 'Be so good they can’t ignore you.',
      th: 'จงเป็นคนเก่งจนไม่มีใครเมินคุณได้'
    }
  },
  {
    author: 'Mandy Hale',
    quote: {
      en: 'There is nothing more beautiful than someone who goes out of their way to make life beautiful for others.',
      th: 'ไม่มีอะไรจะสวยงามไปกว่าการที่คนๆนึงยอมเปลี่ยนแปลงตัวเองเพื่อสร้างชีวิตที่สวยงามให้คนอื่น'
    }
  },
  {
    author: 'George Bernard',
    quote: {
      en: 'Some men see things as they are and say why – I dream things that never were and say why not.',
      th: 'คนบางคนมองสิ่งต่างๆและพูดว่าทำไม ส่วนฉันฝันถึงสิ่งที่ไม่เคยมีแล้วตั้งคำถามว่าทำไมจะเป็นไปไม่ได้'
    }
  },
  {
    author: ' Majora Carter',
    quote: {
      en: 'If we are going to be part of the solution, we have to engage the problems.',
      th: 'หากเราต้องการแก้ปัญหา เราต้องเอาตัวเข้าไปเป็นส่วนหนึ่งของปัญหา'
    }
  },
  {
    author: 'Peter Drucker',
    quote: {
      en: 'The best way to predict the future is to create it.',
      th: 'การทำนายอนาคตที่แม่นที่สุด คือทำสิ่งนั้นให้เป็นจริงซะ'
    }
  },
  {
    author: 'Mahatma Gandhi',
    quote: {
      en: 'Live as if you were to die tomorrow. Learn as if you were to live forever.',
      th: 'ใช้ชีวิตเหมือนว่าคุณจะตายในวันพรุ่งนี้ เรียนรู้ให้เหมือนว่าคุณจะมีชีวิตอยู่ตลอดไป'
    }
  },
  {
    author: 'Vidal Sassoon',
    quote: {
      en: 'The only place where success comes before work is in the dictionary.',
      th: 'ที่เดียวที่ความสำเร็จมาก่อนการทำงาน คือ พจนานุกรม'
    }
  },
  {
    author: 'Ayn Rand',
    quote: {
      en: 'Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.',
      th: 'เงินเป็นเพียงแค่เครื่องมือที่จะพาคุณไปในที่ๆคุณต้องการ แต่เงินไม่ใช่คนขับรถแทนคุณ'
    }
  },
  {
    author: ' Emma Watson',
    quote: {
      en: 'I don’t want other people to decide who I am. I want to decide that for myself.',
      th: 'ฉันไม่ต้องการให้คนอื่นมาตัดสินว่าฉันเป็นใคร ฉันต้องการตัดสินด้วยตัวฉันเอง'
    }
  },
  {
    author: 'Emma Watson',
    quote: {
      en: 'The less you reveal, the more people can wonder.',
      th: 'ยิ่งคุณเปิดเผยน้อยเท่าไหร่ คนก็ยิ่งอยากรู้มากเท่านั้น'
    }
  },
  {
    author: 'Emma Watson',
    quote: {
      en: 'Don’t let anyone tell you what you can and can’t do or achieve. Do what you want to do and be who you want to be.',
      th: 'อย่าปล่อยให้ใครมาบอกว่าคุณทำอะไรได้ คุณทำอะไรไม่ได้ จงทำในสิ่งที่คุณอยากจะทำและเป็นในสิ่งที่คุณอยากจะเป็น'
    }
  },
  {
    author: 'Barack Obama',
    quote: {
      en: 'Money is not the only answer, but it makes a difference.',
      th: 'เงิน ไม่ใช่คำตอบของทุกอย่าง แต่เงินทำให้เกิดความแตกต่างได้'
    }
  },
  {
    author: 'Barack Obama',
    quote: {
      en: 'Change is never easy, but always possible.',
      th: 'การเปลี่ยนแปลงไม่ใช่เรื่องง่าย แต่ก็เป็นไปได้เสมอ'
    }
  },
  {
    author: 'Bill Gates',
    quote: {
      en: 'If you are born poor it’s not your mistake, but if you die poor it\'s your mistake.',
      th: 'ถ้าคุณเกิดมาจน นั่นไม่ใช่ความผิดของคุณ แต่ถ้าคุณตายไปแบบจนๆ นั่นคือความผิดของคุณ'
    }
  },
  {
    author: 'Steve Jobs',
    quote: {
      en: 'Creativity is just connecting things.',
      th: 'ความคิดสร้างสรรค์คือการเชื่อมโยงสิ่งต่างๆเข้าด้วยกัน'
    }
  },
  {
    author: 'Tony Robbins',
    quote: {
      en: 'If you do what you’ve always done, you’ll get what you’ve always gotten.' ,
      th: 'ถ้าคุณทำในสิ่งเดิมๆ คุณก็จะได้ผลลัพธ์แบบเดิมๆ'
    }
  },
  {
    author: 'George Eliot',
    quote: {
      en: 'It is never too late to be what you might have been.',
      th: 'ไม่มีคำว่าสายเกินไป ที่จะเป็นในสิ่งที่คุณอยากจะเป็น'
    }
  },
  {
    author: 'Eleanor Roosevelt',
    quote: {
      en: 'The future belongs to those who believe in the beauty of their dreams.',
      th: 'อนาคตเป็นของทุกคนที่เชื่อมั่นในความฝันที่สวยงามของตัวเอง'
    }
  },
  {
    author: 'Donald Trump',
    quote: {
      en: 'Passion will help you do better.',
      th: 'ความหลงไหล จะช่วยให้คุณเก่งขึ้น'
    }
  },
  {
    author: 'Pablo Picasso',
    quote: {
      en: 'Everything you can imagine is real.',
      th: 'อะไรที่คุณสามารถจินตนาการได้ นั่นคือเรื่องจริง'
    }
  },
  {
    author: 'Oscar Wilde',
    quote: {
      en: 'Be yourself. Everyone else is already taken.',
      th: 'จงเป็นตัวของตัวเอง เพราะการจะเป็นคนอื่น เจ้าของเขาเป็นไปแล้ว'
    }
  },
  {
    author: 'Jim Rohn',
    quote: {
      en: 'Your life does not get better by chance, it gets better by change.',
      th: 'ชีวิตของคุณไม่ได้ดีคุณเพราะโอกาส แต่มันดีขึ้นจากการเปลี่ยนแปลงของคุณ'
    }
  },
  {
    author: 'Leo Tolstoy',
    quote: {
      en: 'Everyone thinks of changing the world, but no one thinks of changing himself.',
      th: 'ทุกคนคิดจะเปลี่ยนโลก แต่ไม่มีใครคิดจะเปลี่ยนตัวเอง'
    }
  },
  {
    author: 'Winston Churchill',
    quote: {
      en: 'To improve is to change; to be perfect is to change often.',
      th: 'การพัฒนาคือการเปลี่ยนแปลง ความสมบูรณ์แบบคือการเปลี่ยนแปลงอย่างสม่ำเสมอ'
    }
  },
  {
    author: 'Henry Ward',
    quote: {
      en: 'Hold yourself responsible for a higher standard than anybody expects of you. Never excuse yourself.',
      th: 'จงทำให้ดีมากกว่าที่คนอื่นคาดหวัง และอย่าแก้ตัว'
    }
  },
  {
    author: 'Leigh Hunt',
    quote: {
      en: 'The groundwork of all happiness is health.',
      th: 'พื้นฐานของความสุข คือ สุขภาพที่ดี'
    }
  },
  {
    author: 'Lucille Ball',
    quote: {
      en: 'I’d rather regret the things that I have done than the things that I have not done.',
      th: 'ฉันไม่เสียดายในสิ่งที่ฉันได้ทำลงได้ ฉันเสียดายในสิ่งที่ไม่ได้ทำมากกว่า '
    }
  },
  {
    author: 'Bobby Mcferrin',
    quote: {
      en: 'In every life, we have some trouble, but when you worry you make it double.',
      th: 'ทุกๆคนต่างมีปัญหาในชีวิตกันทั้งนั้น แต่ถ้าคุณมัวแต่กังวล ปัญหานั้นจะเพิ่มเป็น 2 เท่า'
    }
  },
  {
    author: 'Soyen Shaku',
    quote: {
      en: 'Watch what you say, and whatever you say, practice it.',
      th: 'จดจำในสิ่งที่คุณพูด และลงมือทำในสิ่งที่คุณได้พูดไว้'
    }
  },
  {
    author: 'Oscar Wilde',
    quote: {
      en: 'To live is the rarest thing in the world. Most people exist, that is all.',
      th: 'การใช้ชีวิตเป็นสิ่งที่แทบจะไม่มีใครทำเลย คนส่วนใหญ่แค่มีตัวตนอยู่ แค่นั้น'
    }
  },
  {
    author: 'Abraham Lincoln',
    quote: {
      en: 'In the end, it’s not the years in your life that count. It’s the life in your years.',
      th: 'สุดท้ายแล้ว มันไม่สำคัญว่าคุณมีชีวิตอยู่กี่ปี แต่ตอนที่มีชีวิตอยู่คุณได้ใช้มันดีแค่ไหนนั้นสำคัญกว่า'
    }
  },
  {
    author: 'Oprah Winfrey',
    quote: {
      en: 'You get in life what you have the courage to ask for.',
      th: 'ในชีวิต คุณจะได้รับในสิ่งที่คุณกล้าที่จะร้องขอ'
    }
  },
  {
    author: 'Mitch Albom',
    quote: {
      en: 'Death ends a life, not a relationship.',
      th: 'ความตาย คือการสิ้นสุดชีวิต แต่ไม่ใช่การสิ้นสุดความสัมพันธ์'
     },
  },
  {
    author: 'Napoleon Hill',
    quote: {
      en: 'You may be hurt if you love too much, but you will live in misery if you love too little.',
      th: 'คุณอาจจะเจ็บปวดถ้าคุณรักมากเกินไป แต่คุณจะอยู่อย่างเป็นทุกข์ถ้าคุณรักน้อยเกินไป'
    }
  },
  {
    author: 'Carl Jung',
    quote: {
      en: 'No tree, it is said, can grow to heaven unless its roots reach down to hell.',
      th: 'ไม่มีต้นไม้ต้นไหนที่จะโตไปถึงสวรรค์ ถ้าหากรากไม่หยั่งไปจนถึงนรก'
    }
  },
  {
    author: 'Anne Frank',
    quote: {
      en: 'Dead people receive more flowers than the living ones because regret is stronger than gratitude.',
      th: 'คนตายได้รับดอกไม้มากกว่าคนเป็น เพราะว่าความเสียใจนั้นรุนแรงกว่าความรู้สึกซาบซึ้ง'
    }
  },
  {
    author: 'Jeremiah Say',
    quote: {
      en: 'The less you give a damn to negative people, the more peaceful your life will become.',
      th: 'ยิ่งคุณตอบโต้คนที่คิดลบน้อยเท่าไหร่ ชีวิตของคุณก็จะสงบมากขึ้นเท่านั้น'
    }
  },
  {
    author: 'Donald Kaufman',
    quote: {
      en: 'You are what you love, not what loves you.',
      th: 'จงเป็นคุณในแบบที่คุณรัก ไม่ใช่แบบที่คนอื่นรักคุณ'
    }
  },
  {
    author: 'Leo Buscaglia',
    quote: {
      th: 'ความเสี่ยงเป็นสิ่งที่เราต้องเจอ เพราะสิ่งที่อันตรายที่สุดในชีวิตคือการไม่เสี่ยงอะไรเลย',
      en: 'Risks must be taken, because the greatest hazard in life is to risk nothing.',
    }
  }
];

export default quotes;

import React from 'react';

import styles from './Block.module.css';

const Block = ({ children, style, className,  ...rest }) => (
  <div className={`container mb-3 ${className && className} ${styles.card}`} style={{...style}} {...rest}>
    {children}
  </div>
);

export default Block;
import React from 'react';

import Block from '../Block';

import { quotes } from '../../datasource';

const getMotivationalQuoteForTheDay = (date) => {
  const numberOfQuotes = quotes.length;
  const todayDay = date.ordinal;

  const motivationIndex = todayDay % numberOfQuotes;

  return quotes[motivationIndex];
};

const MotivationBlock = ({ t, lang, date }) => {
  const motivationBlock = getMotivationalQuoteForTheDay(date);

  let quote = motivationBlock.quote.th;

  if (lang === 'en') {
    quote = motivationBlock.quote.en;
  }
  return (
    <Block>
      <div className="columns">
        <div className="column" style={{color: 'white'}}>
          <p className="subtitle is-4 mb-5" style={{color: 'inherit'}}>{t('motivation_title')}</p>
          <p className="subtitle is-4" style={{color: 'inherit'}}>
            {quote}
          </p>
          <p className="subtitle is-5 has-text-right" style={{color: 'inherit'}}>
            - {motivationBlock.author} -
          </p>
        </div>
      </div>
    </Block>
  );  
};

export default MotivationBlock;

import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/all.css';

import App from './containers/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { translations } from './datasource';
import { getStoredLangPreference, getWeatherData } from './utils';

import './index.css';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    lng: getStoredLangPreference(),
    fallbackLng: "th",

    interpolation: {
      escapeValue: false
    }
  });

// Ensure weather data
getWeatherData();

ReactDOM.render(
  <React.StrictMode>
    <Helmet>      
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Nunito:wght@500;700;900&display=swap" rel="stylesheet" />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      registration.update();
      console.log("Updating Registration");
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      console.log("Skipping waiting");
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    } else {
      console.log("No service worker waiting. All good");
    }
  },
  onSuccess: (registration) => {
    console.log(registration);
  }
});

window.addEventListener('beforeinstallprompt', (e) => {
  console.log('Caught beforeinstallprompt');
});
import React, { useEffect, useState } from 'react';

import Block from '../Block';

import { ReactComponent as FlagIcon } from '../../assets/flags/flag.svg';

import styles from './ColorBlock.module.css';

const luckColors = {
  1: {
    money: {
      label: "Orange",
      hexCode: "#FFA62B",
      endHexCode: "#FF990A",
      fontColor: "#000",
      weekday: 1,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Pink",
      hexCode: "#F0569B",
      endHexCode: "#ED3185",
      fontColor: "#000",
      weekday: 1,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Cream",
      hexCode: "#FFF0EB",
      endHexCode: "#FFE0D6",
      fontColor: "#000",
      weekday: 1,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    }
  },
  2: {
    money: {
      label: "Green",
      hexCode: "#50FFB1",
      endHexCode: "#00F587",
      fontColor: "#000",
      weekday: 2,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Orange",
      hexCode: "#FFA62B",
      endHexCode: "#FF990A",
      fontColor: "#000",
      weekday: 2,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Purple",
      hexCode: "#C488FC",
      endHexCode: "#A64CFA",
      fontColor: "#000",
      weekday: 2,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  },
  3: {
    money: {
      label: "Blue",
      hexCode: "#1F66FF",
      endHexCode: "#4782FF",
      fontColor: "#000",
      weekday: 3,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Yellow",
      hexCode: "#FFD485",
      endHexCode: "#FFC65C",
      fontColor: "#000",
      weekday: 3,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Black",
      hexCode: "#080F0F",
      endHexCode: "#0E0F1B",
      fontColor: "#000",
      weekday: 3,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  },
  4: {
    money: {
      label: "Red",
      hexCode: "#C5283D",
      endHexCode: "#A92335",
      fontColor: "#000",
      weekday: 4,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Grey",
      hexCode: "#999999",
      endHexCode: "#C2C2C2",
      fontColor: "#000",
      weekday: 4,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Orange",
      hexCode: "#FFA62B",
      endHexCode: "#FF990A",
      fontColor: "#000",
      weekday: 4,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  }, 
  5: {
    money: {
      label: "Pink",
      hexCode: "#F0569B",
      endHexCode: "#ED3185",
      fontColor: "#000",
      weekday: 5,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Blue",
      hexCode: "#1F66FF",
      endHexCode: "#4782FF",
      fontColor: "#000",
      weekday: 5,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Green",
      hexCode: "#50FFB1",
      endHexCode: "#00F587",
      fontColor: "#000",
      weekday: 5,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  },
  6: {
    money: {
      label: "Black",
      hexCode: "#080F0F",
      endHexCode: "#0E0F1B",
      fontColor: "#000",
      weekday: 6,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Red",
      hexCode: "#C5283D",
      endHexCode: "#A92335",
      fontColor: "#000",
      weekday: 6,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Blue",
      hexCode: "#1F66FF",
      endHexCode: "#4782FF",
      fontColor: "#000",
      weekday: 6,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  },
  7: {
    money: {
      label: "Purple",
      hexCode: "#C488FC",
      endHexCode: "#A64CFA",
      fontColor: "#000",
      weekday: 7,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    work: {
      label: "Green",
      hexCode: "#50FFB1",
      endHexCode: "#00F587",
      fontColor: "#000",
      weekday: 7,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
    love: {
      label: "Pink",
      hexCode: "#F0569B",
      endHexCode: "#ED3185",
      fontColor: "#000",
      weekday: 7,
      description: 'This is a super general description about the color.  It can be somewhat long probably around 256 characters.',
    },
  },
};

const AuspiciousTitle = ({ t }) => (
  <div className={`${styles.auspiciousTitleWrapper} mb-5`}>
    (
      <FlagIcon width="16" height="16" className="mr-1" fill="#BC002D" />
      <p className="subtitle is-5" style={{color: 'inherit'}}>{t('lucky_color_auspicious_title')}</p>
    )
  </div>
);

const ColorBox = ({ className, startColor, endColor }) => (
  <div className={`${styles.colorBlock} ${className && className}`} style={{background: `linear-gradient(120deg, ${startColor} 0%, ${endColor} 100%)`, color: 'inherit' }} />
);

const ColorDescription = ({ color, label }) => (
  <div className={`column ${styles.colorContent}`}>
    <div className="columns is-mobile">
      <div className="column is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
        {/* <ColorBlob2 className="mb-1" fill={color.hexCode} width="62px" height="62px" /> */}
        <ColorBox className="mb-1" startColor={color.hexCode} endColor={color.endHexCode} />
        <p className={`subtitle is-6 has-text-centered ${styles.text}`} style={{whiteSpace: 'nowrap'}}>{label}</p>
      </div>
    </div>      
  </div>
)

const ColorDescriptions = ({ colors, t }) => (
  <div className="columns is-flex-direction-row is-mobile is-flex-wrap-nowrap mt-3">
    <ColorDescription color={colors.money} label={t('lucky_color_money')} icon= "far fa-money-bill-alt" />
    <ColorDescription color={colors.work} label={t('lucky_color_work')} icon="far fa-building" />    
    <ColorDescription color={colors.love} label={t('lucky_color_love')} icon="far fa-heart" />    
  </div>
)

const ColorBlock = ({ weekday, t, isAuspicious }) => {
  const [color, setColor] = useState(luckColors[weekday]);  

  useEffect(() => {
    setColor(luckColors[weekday]);
    // eslint-disable-next-line react-hooks/exhaustive-deps    
  }, [weekday]);

  return (
    <Block>
      <div className={`columns is-mobile ${styles.colorBlockWrapper}`}>    
        <div className={`column`} style={{color: 'white'}}>
          <p className="subtitle is-4 mb-1" style={{color: 'inherit'}}>{t('lucky_color_title')}</p>
          {isAuspicious ?  <AuspiciousTitle t={t} /> : <div className="mb-5" />}
          <ColorDescriptions colors={color} t={t} />
          <p className="subtitle is-7 mb-1" style={{color: 'inherit'}}>{t('lucky_color_attribution')}</p>
        </div>          
      </div>
    </Block>
  );
};

export default ColorBlock;

import React from 'react';

import styles from './Button.module.css';

const getStyleForType = (type) => {
  switch (type) {
    case 'primary':
      return styles.primary;
    case 'accent':
      return styles.accent;
    case 'secondary':
      return styles.secondary;
    case 'white':
      return 'is-white';
    case 'transparent':
      return styles.transparent;
    default:
      return '';
  }
};

const getStyleForSize = (size) => {
  switch (size) {
    case 'small':
      return `is-${size} ${styles.small}`;
    default:
      return `is-${size}`;
  }
};

const Button = ({ className, style, children, type, size, onClick, compact, ...props }) => (
  <button {...props} className={`button ${getStyleForSize(size)} ${styles.button} ${getStyleForType(type)} ${compact && styles.compact} ${className}`} onClick={onClick} style={style}>
    {
      children
    }
  </button>
);

export default Button;

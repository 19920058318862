import React, { useState } from "react";
import { DateTime } from "luxon";
import { useTranslation } from 'react-i18next';

import { dateFormatForLocale } from "../../utils";

import ContentBox from '../ContentBox';
import Button from '../Button';

import { events as eventList, auspiciousDays, prayDays } from "../../datasource";

import januarybg from "../../assets/banners/jan.gif";
import febbg from "../../assets/banners/feb.gif";
import marchbg from "../../assets/banners/mar.gif";
import aprilbg from "../../assets/banners/apr.gif";
import maybg from "../../assets/banners/may.gif";
import junebg from "../../assets/banners/jun.gif";
import julybg from "../../assets/banners/jul.gif";
import augbg from "../../assets/banners/aug.gif";
import septbg from "../../assets/banners/sep.gif";
import octoberbg from "../../assets/banners/oct.gif";
import novemberbg from "../../assets/banners/nov.gif";
import decemberbg from "../../assets/banners/dec.gif";
import auspiciousIcon from "../../assets/events/ext/pray.svg";

import { ReactComponent as FrontIcon } from "../../assets/icons/front.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";

import styles from "./Calendar.module.css";

const headerForMonth = (month) => {
  switch (month) {
    case 1:
      return { bg: januarybg, pos: 'bottom'};
    case 2:
      return { bg: febbg, pos: 'center'};
    case 3:
      return { bg: marchbg, pos: 'top'};
    case 4:
      return { bg: aprilbg, pos: 'center'};
    case 5:
      return { bg: maybg, pos: 'bottom'};
    case 6:
      return { bg: junebg, pos: 'center'};
    case 7:
      return { bg: julybg, pos: 'top'};
    case 8:
      return { bg: augbg, pos: 'bottom'};
    case 9:
      return { bg: septbg, pos: 'center'};
    case 10:
      return { bg: octoberbg, pos: 'center'};
    case 11:
      return { bg: novemberbg, pos: 'center'};
    case 12:
      return { bg: decemberbg, pos: 'bottom'};
    default:
      return { bg: octoberbg, pos: 'center'};
  }
}

const ModeLink = ({ onClick, mode, icon, title, type, size = "small", className, children }) => {
  const onModeClick = (e) => {
    e.preventDefault();

    onClick(mode)
  }

  return (
    <Button className={className} onClick={onModeClick} title={title} size={size} type={type ? type : "secondary"}>
      { icon ? (<span className={`icon ${styles.modeLinkColor}`}>
        <i className={icon} />
      </span>) : '' }
      {title ? title : ''}
      {children}
    </Button>
  );
}

const DayBlock = ({ date, label, isHeader = false , isSelected, isToday, disabled, isWeekend, events, onClick, bgImg, smaller, isPrayDay }) => {
  const onDayClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick(date);
    }
  };
  const style = {};
  if (bgImg) {
    style.backgroundImage = `url(${bgImg})`
  }

  const event = events[0];
  let eventIndicator;
  events.forEach((ev) => {
    if (ev.eventIndicator) {
      eventIndicator = ev.eventIndicator;
    }
  });

  return (
    <div className={`column ${styles.dayBlock} ${disabled && styles.dayBlockDisabled} ${isWeekend && styles.dayBlockWeekend} ${event && event.holidayType === 'royal' && styles.dayBlockContentRoyal}`} onClick={onDayClick}>
      <div className={`${styles.dayBlockContent}
        ${isHeader && styles.dayBlockContentHeader}
        ${isToday && styles.dayBlockContentToday}
        ${isSelected && styles.dayBlockContentSelected}
        ${disabled && styles.dayBlockContentDisabled}
        ${smaller && styles.dayBlockSmallerContent}
      `} style={style}>
        {
          !bgImg && label
        }
        {isPrayDay && <img className={`${styles.dayEventAuspiciousIndicator} image`} src={auspiciousIcon} alt="Auspicious Day" />}
        {event && !bgImg && !event.eventIndicator && event.holidayType !== 'royal' && <div className={`${styles.dayEventIndicator} ${isToday && styles.dayEventIndicatorToday}`} />}
        {eventIndicator && <img className={`${styles.dayEventIndicatorImage} image`} src={eventIndicator} alt={event.label} />}
      </div>
    </div>
  );
};

const Days = ({ from, today, events, totalDays = 35, selected, onClick, month, lang }) => {
  const { t } = useTranslation();

  const renderDays = () => {
    let dayComponents = [];
    for(let i = 0; i < totalDays; i++) {
      const date = from.plus({ days: i });
      const dayEvents = events.filter(e => DateTime.fromISO(e.date).hasSame(date, "day") && DateTime.fromISO(e.date).hasSame(date, "month") && DateTime.fromISO(e.date).hasSame(date, "year"));
      const dateString = date.toFormat('dd-MM-yyyy');
      const isPrayDay = prayDays.includes(dateString);
      dayComponents.push((<DayBlock
        key={i}
        date={date}
        label={date.day}
        isToday={date.hasSame(today, "day")}
        isSelected={selected && date.hasSame(selected, "day")}
        disabled={date.month !== month}
        isWeekend={date.weekday > 5}
        events={dayEvents}
        onClick={onClick}
        bgImg={dayEvents.length > 0 && dayEvents[0].dayBlockImg}
        isPrayDay={isPrayDay}
      />))
    }
    return dayComponents;
  }

  return (
    <>
      <div className={`columns is-mobile ${styles.calendarRow}`} style={{marginBottom: 0}}>
        <DayBlock key="sun" label={t('calendar_sun')} isHeader={true} events={[]} isWeekend={true} smaller={lang === 'en'} />
        <DayBlock key="mon" label={t('calendar_mon')} isHeader={true} events={[]} smaller={lang === 'en'} />
        <DayBlock key="tue" label={t('calendar_tue')} isHeader={true} events={[]} smaller={lang === 'en'} />
        <DayBlock key="wed" label={t('calendar_wed')} isHeader={true} events={[]} smaller={lang === 'en'} />
        <DayBlock key="thu" label={t('calendar_thu')} isHeader={true} events={[]} smaller={lang === 'en'} />
        <DayBlock key="fri" label={t('calendar_fri')} isHeader={true} events={[]} smaller={lang === 'en'} />
        <DayBlock key="sat" label={t('calendar_sat')} isHeader={true} events={[]} isWeekend={true} smaller={lang === 'en'} />
      </div>
      <div className={`columns is-mobile ${styles.calendarRow}`}>
        {
          renderDays()
        }
      </div>
    </>
  )
};

const Month = ({ firstDay, now, setSelectedDate, selectedDate, events, mode, onModeChange, onNextPeriod, onPreviousPeriod, lang }) => {
  const { t } = useTranslation();
  let totalDays = 35;
  const toggleMode = () => {
    const newMode = mode === 'week' ? 'month' : 'week';
    onModeChange(newMode);
  }
  let modeTitle = t('calendar_week');

  if (mode === 'week') {
    totalDays = 7;
    modeTitle = t('calendar_month');
  }
  let from = firstDay.minus({ days: firstDay.weekday })
  if (firstDay.weekday === 7) {
    from = firstDay;
  }

  const headerDate = from.plus({ days: Math.round(totalDays / 2)});

  if (mode === 'month') {
    const lastDayOfMonth = headerDate.endOf('month');
    let dayDiffObj = lastDayOfMonth.diff(from, 'days');
    let dayDiff = Math.round(dayDiffObj.days);
    if (dayDiff > totalDays) {
      const daysToAdd = lastDayOfMonth.weekday === 7 ? 6 : (6 - lastDayOfMonth.weekday);
      let lastDayForWeek = lastDayOfMonth.plus({ days: daysToAdd });
      dayDiffObj = lastDayForWeek.diff(from, 'days');
      dayDiff = Math.round(dayDiffObj.days);
            
      totalDays = dayDiff;
      if (lastDayForWeek.weekday === 7) {
        console.log('Total days', totalDays);
        totalDays = totalDays + 6;
        console.log('New Total days', totalDays);
      }
    }
  }

  const headerStyle = headerForMonth(headerDate.month);
  let format = 'MMMM y';
  if (lang === 'en') {
    format = 'MMMM, y';
  }
  return (
    <div className={styles.month}>
      <div className={styles.headerWrapper} style={{backgroundImage: `url(${headerStyle.bg})`, backgroundPosition: headerStyle.pos}}>
      </div>
      <div className={`level is-mobile ${styles.headerToolbar}`}>
        <div className="level-left">
          <div className="level-item" style={{marginRight: '0.35rem'}}>
            <ModeLink key="prev-button" className="p-0" onClick={onPreviousPeriod} mode="back" type="transparent">
              <BackIcon key="prev" />
            </ModeLink>
          </div>
          <div className={`level-item has-text-centered ${styles.calendarHeaderTitle}`}>
            {dateFormatForLocale({date: headerDate, lang, format})}
          </div>
        </div>

        <div className="level-right">
          <div className="level-item">
            <ModeLink title={modeTitle} onClick={toggleMode} mode={mode} />
          </div>          
          <div className="level-item">
            <ModeLink key="next-button" className="p-0" onClick={onNextPeriod} mode="next" type="transparent">
              <FrontIcon key="next" />
            </ModeLink>
          </div>
        </div>
      </div>
      <Days
        from={from}
        today={now}
        events={events}
        totalDays={totalDays}
        selected={selectedDate}
        onClick={setSelectedDate}
        month={headerDate.month}
        lang={lang}
      />
    </div>
  )
};

const StickerModal = ({ eventId, src, alt, format, onClose, active = true }) => {
  const closeSticker = () => {
    onClose();
  }
  let Sticker = () => (
    <figure className={`image ${styles.stickerModalStickerWrapper}`}>
      <img className={styles.stickerModalSticker} src={src} alt={alt} />
    </figure>
  );
  if (format === 'video') {
    Sticker = () => (
      <video className={`image ${styles.stickerModalStickerWrapper}`} autoPlay loop muted playsInline>
        <source src={src} type="video/mp4"></source>
      </video>
    )
  }

  return (
    <div className={`modal ${active && 'is-active'}`}>
      <div className={`modal-background ${styles.stickerBackground}`}></div>
      <div className="modal-content">
        <Sticker />
      </div>
      <button className={`modal-close is-large ${styles.stickerCloseButton}`} aria-label="close" onClick={closeSticker}></button>
    </div>
  );
};

const Calendar = ({ lang }) => {
  const [calendarMode, setCalendarMode] = useState("week");
  const [renderSticker, setRenderSticker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(DateTime.local());
  const [currentCalendarPeriodFirstDay, setCurrentCalendarPeriodFirstDay] = useState(DateTime.local().weekday === 7 ? DateTime.local() : DateTime.local().startOf('week'));
  const [events, setEvents] = useState(eventList);

  const now = DateTime.local();

  const setNextPeriod = () => {
    let nextPeriodFirstDay = currentCalendarPeriodFirstDay.set({ month: currentCalendarPeriodFirstDay.month + 1 });
    if (calendarMode === 'week') {
      nextPeriodFirstDay = currentCalendarPeriodFirstDay.set({ day: currentCalendarPeriodFirstDay.day + 7 });
    }
    setCurrentCalendarPeriodFirstDay(nextPeriodFirstDay);
  }
  const setPrevPeriod = () => {
    let nextPeriodFirstDay = currentCalendarPeriodFirstDay.set({ month: currentCalendarPeriodFirstDay.month - 1 });
    if (calendarMode === 'week') {
      nextPeriodFirstDay = currentCalendarPeriodFirstDay.set({ day: currentCalendarPeriodFirstDay.day - 7 });
    }
    setCurrentCalendarPeriodFirstDay(nextPeriodFirstDay);
  }

  const changeCalendarMode = (mode) => {
    if (mode === 'week' && selectedDate.weekday === 7) {
      setCurrentCalendarPeriodFirstDay(selectedDate);
    } else {
      setCurrentCalendarPeriodFirstDay(selectedDate.startOf(mode));
    }

    setCalendarMode(mode);
  }

  const showSticker = () => {
    setRenderSticker(true);
  }

  const onClickOnDate = (date) => {
    setSelectedDate(date);
    setRenderSticker(true);
  }
  const monthEvents = events.filter(e => currentCalendarPeriodFirstDay.hasSame(selectedDate, "month") && DateTime.fromISO(e.date).hasSame(selectedDate, "month") && DateTime.fromISO(e.date).hasSame(selectedDate, "year"));
  const dayEvents = monthEvents.filter(e => DateTime.fromISO(e.date).hasSame(selectedDate, "day") && DateTime.fromISO(e.date).hasSame(selectedDate, "month") && DateTime.fromISO(e.date).hasSame(selectedDate, "year"));
  const mainEvent = dayEvents[0];

  const dateString = selectedDate.toFormat('dd-MM-yyyy');
  const isAuspicious = auspiciousDays.includes(dateString);

  const closeSticker = () => {
    setRenderSticker(false);
  }
  return (
    <div className={`${styles.calendar}`}>
      <div className={`${styles.months} mb-3`}>
      <Month
        firstDay={currentCalendarPeriodFirstDay}
        now={now}
        setSelectedDate={onClickOnDate}
        selectedDate={selectedDate}
        events={events}
        mode={calendarMode}
        onModeChange={changeCalendarMode}
        onNextPeriod={setNextPeriod}
        onPreviousPeriod={setPrevPeriod}
        lang={lang}
      />
      </div>
      <ContentBox date={selectedDate} now={now} event={mainEvent} events={dayEvents} lang={lang} isAuspicious={isAuspicious} showSticker={showSticker} />
      { mainEvent && mainEvent.sticker && renderSticker && <StickerModal src={mainEvent.sticker} alt={mainEvent.label} format={mainEvent.popupFormat} eventId={mainEvent.id} onClose={closeSticker} /> }
    </div>
  )
}

export default Calendar;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { launchedAsApp, getEcosystem, storeLangPreference } from '../../utils';

import Calendar from '../../components/Calendar';
import Button from "../../components/Button";
import logo from "../../assets/aeonth.png";

import { ReactComponent as THFlagIcon } from "../../assets/flags/th.svg";
import { ReactComponent as ENFlagIcon } from "../../assets/flags/us.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/export.svg";
import safariIcon from "../../assets/icons/safari.png";

import './App.css';
import '../../themes/aeon.css';

const Navbar = ({ showInstall, onInstall, lang }) => { 
  const { i18n, t } = useTranslation();

  const toggleLang = (e) => {
    e.preventDefault();

    const currentLang = i18n.language;
    const newLang = currentLang === 'en' ? 'th' : 'en';
    storeLangPreference(newLang);
    i18n.changeLanguage(newLang);
  }
  return (
    <nav className="navbar" role="navigation" aria-label="main navigation" style={{display: 'flex'}}>    
      <div className="navbar-brand is-flex-grow-1">
        <a className="navbar-item" href="/">
          <img src={logo} alt="AEON" style={{maxHeight: "2.4rem", height: "2.4rem", width: "auto"}} />
        </a>      
      </div>
      <div className="navbar-menu is-flex-mobile" style={{boxShadow: 'none'}}>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Button size="small" type="white" onClick={toggleLang}>
                <span className="icon">
                  {lang === 'th' ? (<THFlagIcon width="24px" />) : (<ENFlagIcon width="24px" />) }
                </span>
              </Button>
              {showInstall && (<Button size="small" type="accent" onClick={onInstall}><strong>{t('navbar_install')}</strong></Button>)}            
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

const IOSInstallModal = ({ show, onHide, t }) => (
  <div className={`modal ${show && 'is-active'}`}>
    <div className="modal-background"></div>
    <div className="modal-content">
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="title is-6">{t('install_instruction_step_one')} <span className="icon"><img className="image is-16x16" alt="Safari" src={safariIcon} /></span></p>
          </div>
          <div className="column">
            <p className="title is-6">{t('install_instruction_step_two_one')} <span className="tag is-light"><span className="icon is-small"><ExportIcon fill="cornflowerblue" height="32" width="32"/></span></span> {t('install_instruction_step_two_two')}</p>
          </div>
          <div className="column">
            <p className="title is-6">{t('install_instruction_step_three_one')} <span className="tag is-light">Add to Home Screen</span> {t('install_instruction_step_three_two')}</p>
          </div>
          <div className="column">
            <p className="title is-6">{t('install_instruction_step_four_one')} <span className="tag is-light">Add</span> {t('install_instruction_step_four_two')}</p>
          </div>
          <div className="column">
            <p className="title is-6">{t('install_instruction_step_five')}</p>
          </div>
        </div>
      </div>
    </div>
    <button className="modal-close is-large" aria-label="close" onClick={onHide}></button>
  </div>
);

const AndroidInstallModal = ({ show, onHide, t }) => (
  <div className={`modal ${show && 'is-active'}`}>
    <div className="modal-background"></div>
    <div className="modal-content">
      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="title is-6">{t('android_install_instruction_step_one')}</p>
          </div>
          <div className="column">
            <p className="title is-6">{t('androind_install_instruction_step_two_one')}<span className="icon is-small"><i className="fas fa-ellipsis-v" /></span>{t('androind_install_instruction_step_two_two')}</p>
          </div>
          <div className="column">
            <p className="title is-6">{t('android_install_instruction_step_three_one')} <span className="tag is-light">Add to Home Screen</span> {t('android_install_instruction_step_three_three')} <span className="tag is-light">{t('android_install_instruction_step_three_four')}</span></p>
          </div>
        </div>
      </div>
    </div>
    <button className="modal-close is-large" aria-label="close" onClick={onHide}></button>
  </div>
);

function App() {  
  const [installProposalEvent,  setInstallProposalEvent] = useState(null);
  const [appInstalled, setAppInstalled] = useState(false);
  const [showIOSInstallModal, setShowIOSInstall] = useState(false);
  const [showAndroidInstallPopup, setShowAndroidInstallPopup] = useState(false);
  const [currentLang, setCurrentLang] = useState();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (window) {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()

        console.log('beforeinstallprompt event called');
        setInstallProposalEvent(e)
      });
    }
  }, []);

  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);


  const launchedAsWebapp = !launchedAsApp();
  const proposalEventCaught = installProposalEvent !== null;
  const ecosystem = getEcosystem();

  let showInstallButton = launchedAsWebapp;
  let disableInstallButton = !proposalEventCaught;

  if (ecosystem !== 'Apple') {
    showInstallButton = launchedAsWebapp && !appInstalled;
  } else {
    disableInstallButton = false;
  }

  const hideIOSInstallModal = (e)  => {
    e.preventDefault();

    setShowIOSInstall(false);
  }

  const hideAndroidInstallModal = (e)  => {
    e.preventDefault();

    setShowAndroidInstallPopup(false);
  }

  const onInstall = (e) => {
    e.preventDefault();

    const ecosystem = getEcosystem();
    if (ecosystem === 'Apple') {
      setShowIOSInstall(true);
    } else if (installProposalEvent) {      
      installProposalEvent.prompt();      

      installProposalEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setAppInstalled(true);
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
    } else {
      setShowAndroidInstallPopup(true);
    }
  }

  console.log(`launched as web app: ${launchedAsWebapp} and is installed: ${appInstalled} on ecosystem ${ecosystem}. Proposal event was caught? ${proposalEventCaught} & disable button ${disableInstallButton}`);

  return (
    <>
      <Navbar showInstall={showInstallButton} onInstall={onInstall} lang={currentLang} />
      <div className="container">
        <Calendar lang={currentLang} />
      </div>

      <IOSInstallModal show={showIOSInstallModal} onHide={hideIOSInstallModal} t={t} />
      <AndroidInstallModal show={showAndroidInstallPopup} onHide={hideAndroidInstallModal} t={t} />
    </>
  );
}

export default App;

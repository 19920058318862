import React, { useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import ColorBlock from '../ColorBlock';
import WeatherBlock from '../../containers/WeatherBlock';
import MotivationBlock from '../MotivationBlock';
import AnimalBlock from '../AnimalBlock';

import styles from './ContentBox.module.css';

import { ReactComponent as LuckyColorIcon } from '../../assets/icons/color.svg';
import { ReactComponent as AnimalIcon } from '../../assets/icons/cow.svg';
import { ReactComponent as QuoteIcon } from '../../assets/icons/quote.svg';
import { ReactComponent as WeatherIcon } from '../../assets/icons/weather.svg';

const TabBarItem = ({ Item, onClick, isActive }) => {
  const handleClick = (e) => {
    e.preventDefault();

    onClick(Item.value);
  }
  return (
    <li className={`${styles.tabbarItem} ${isActive && styles.activeTabbarItem}`}>
      <a className={styles.tabbarItemContentWrapper} onClick={handleClick}>
        <span className="icon is-medium"><Item.Icon width={Item.size} height={Item.size} /></span>
        <span>{Item.label}</span>
      </a>
    </li>
  )
}
const TabBar = ({ tabItems, activeTab, onTabClick }) => {
  const tabItemComps = tabItems.map((item) => (
    <TabBarItem key={item.value} Item={item} onClick={onTabClick} isActive={activeTab === item.value} />
  ));
  return (
    <div className={`tabs is-fullwidth ${styles.tabbar}`}>
      <ul>
        {
          tabItemComps
        }
      </ul>
    </div>
  )
}

const Content = ({ view, date, today, t, lang, isAuspicious }) => {
  switch (view) {
    case 'motivation':
      return <MotivationBlock t={t} lang={lang} date={date} />
    case 'color':
      return <ColorBlock t={t} lang={lang} weekday={date.weekday} isAuspicious={isAuspicious} />
    case 'weather':
      return <WeatherBlock t={t} lang={lang} today={today} />
    case 'animal':
      return <AnimalBlock t={t} lang={lang} date={date} />
    default:
      return ''
  }
}

const EventThumbnail = ({ thumbnail, alt, format, onClick }) => {
  if (format === 'video') {
    return (
      <video className={styles.eventThumbnail} autoPlay loop muted playsInline>
        <source src={thumbnail} type="video/mp4"></source>
      </video>
    )
  }
  const handleClick = (e) => {
    e.preventDefault();
    console.log('Clicking');
    onClick();
  }
  return (
    <img className={styles.eventThumbnail} alt={alt} src={thumbnail} width="128" height="128" onClick={handleClick} />
  );
}

const JPFlagComp = () => (
  <span className="mr-2" role="img" aria-label="Japan flag"> 🇯🇵
  </span>
);

const EventItem = ({ event, lang, showSticker }) => {
  let label = event.label_th;
  let description = event.description_th;

  if (lang === 'en') {
    label = event.label_en;
    description = event.description_en;
  }

  const labelIcon = event.labelIcon;

  return (
    <div className={`columns is-mobile ${styles.eventItemWrapper}`}> 
      {
        event.thumbnail && <div className="column is-narrow"><EventThumbnail key={event.id} thumbnail={event.thumbnail} alt={event.label} format={event.popupFormat} onClick={showSticker} /></div>
      }      
      <div className={`column ${!event.thumbnail && styles.eventItemNoThumbnail}`}>
        <div className="is-flex is-flex-direction-column is-align-items-flex-start is-justify-content-flex-start">
          {label && (
            <>              
              <p className={`title is-4 is-flex-grow-1 has-text-weight-bold mb-3 ${styles.eventText}`} style={{marginTop: 0}}>              
                { event.holidayType === 'jp' && <JPFlagComp />}
                { labelIcon && <img src={labelIcon} className="image is-32x32 mr-1" alt={label} />}
                {label}
              </p>
            </>
          )}
          {description && description.trim() !== '' && <p className={`subtitle is-6 is-flex-grow-1 ${styles.eventText}`} style={{marginTop: 0}}>{description}</p>}
        </div>
      </div>
    </div>
  );
}

const EventsBlock = ({ event, lang, showSticker, events }) => (
  <div className={`${styles.eventBlock}`}>
    <div className="columns is-mobile">
      <div className="column">
        {
          events.map((ev, index) => (<EventItem key={ev.id} event={ev} lang={lang} showSticker={showSticker} />))
        }
      </div>
    </div>
  </div>
);

const ContentBox = ({ date, now, event, lang, isAuspicious, showSticker, events }) => {
  const [activeTab, setActiveTab] = useState('color');
  const [scroll, setScroll] = useState(false);
  const contentBoxRef = useRef(null);

  const contentId = 'content-wrapper';

  const { t } = useTranslation();
  const tabItems = [
    {label: t('tab_lucky_color'), Icon: LuckyColorIcon, value: 'color', size: '24px' },
    {label: t('tab_zodiac'), Icon: AnimalIcon, value: 'animal', size: '20px' },
    {label: t('tab_motivation'), Icon: QuoteIcon, value: 'motivation', size: '36px' },
    {label: t('tab_weather'), Icon: WeatherIcon, value: 'weather', size: '24px' }
  ];

  if (scroll) {
    contentBoxRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
      alignToTop: false
    });
    setScroll(false);
  }

  const activateTab = (tab) => {
    if (tab !== activateTab) {
      setActiveTab(tab);
      setTimeout(() => {
        setScroll(true);
      }, 0);      
    }    
  }

  return (
    <div ref={contentBoxRef} id={contentId} className={styles.contentBox}>
      {event && <EventsBlock events={events} event={event} lang={lang} showSticker={showSticker} />}
      <Content view={activeTab} date={date} today={now} t={t} lang={lang} isAuspicious={isAuspicious} />  
      <TabBar
        tabItems={tabItems}
        activeTab={activeTab}
        onTabClick={activateTab}
      />
    </div>
  );
};

export default ContentBox;
const translations = {
  en: {
    translation: {
      "navbar_install": "Install",
      "tab_lucky_color": "Lucky Color",
      "tab_motivation": "Motivation",
      "tab_zodiac": "Zodiac",
      "tab_weather": "Weather",      
      "calendar_month": "Month",
      "calendar_week": "Week",
      "calendar_sun": "Sun",
      "calendar_mon": "Mon",
      "calendar_tue": "Tue",
      "calendar_wed": "Wed",
      "calendar_thu": "Thu",
      "calendar_fri": "Fri",
      "calendar_sat": "Sat",
      "lucky_color_title": "Lucky color for the day",
      "lucky_color_auspicious_title": "Auspicious Day",
      "lucky_color_money": "Money",
      "lucky_color_work": "Work",
      "lucky_color_love": "Love",
      "lucky_color_attribution": "* Reference by Ajarn Ae Rada, Change color, Change life",
      "motivation_title": "Motivation for the day",
      "zodiac_title": "Zodiac for the day",
      "zodiac_dont_get_along": "won't get along with",
      "weather_title": "Weather for Today",
      "weather_chance_of_rain": "There is a {{chance_of_rain}}% chance of rain",
      "weather_partly_cloudy": "Partly Cloudy",
      "weather_aqi_good": "Good",
      "weather_aqi_good_description": "Enjoy the fresh clean air",
      "weather_aqi_moderate": "Moderate",
      "weather_aqi_moderate_description": "Air quality is acceptable but there may be risks for sensitive individuals",
      "weather_aqi_bad": "Bad",
      "weather_aqi_bad_description": "Sensitive individuals should reduce outdoor activities",
      "weather_aqi_unhealthy": "Unhealthy",
      "weather_aqi_unhealthy_description": "Sensitive individuals should avoid outdoor activities",      
      "weather_aqi_very_unhealthy": "Very Unhealthy",
      "weather_aqi_very_unhealthy_description": "Health Risk: Everyone should avoid all physical outdoor activities",
      "weather_aqi_hazardous": "Hazardous",
      "weather_aqi_hazardous_description": "Hazard warning: stay indoors",
      "install_instruction_step_one": "Step 1: Make sure you open the Aeon Calendar in the Safari web browser",
      "install_instruction_step_two_one": "Step 2: Tap on",
      "install_instruction_step_two_two": "icon in the bottom bar",
      "install_instruction_step_three_one": "Step 3: Scroll and tap on the",
      "install_instruction_step_three_two": "option",
      "install_instruction_step_four_one": "Step 4: Find the",
      "install_instruction_step_four_two": "button in the top right corner and tap on it",
      "install_instruction_step_five": "Step 5: The app should be on your Homescreen",
      "thunderstorm-with-light-rain": "Thunderstorm with light rain",
      "thunderstorm-with-rain": "Thunderstorm with light rain",
      "thunderstorm-with-heavy-rain": "Thunderstorm with heavy rain",
      "light-thunderstorm": "Light Thunderstorm",
      "thunderstorm": "Thunderstorm",
      "ragged-thunderstorm": "Ragged Thunderstorm",
      "thunderstorm-with-light-drizzle": "Thunderstorm with light drizzle",
      "thunderstorm-with-drizzle": "Thunderstorm with drizzle",
      "thunderstorm-with-heavy-drizzle": "Thunderstorm with heavy drizzle",
      "light-intensity-drizzle": "Light intensity drizzle",
      "drizzle": "Drizzle",
      "heavy-intensity-drizzle": "Heavy intensity drizzle",
      "light-intensity-drizzle-rain": "Light intensity drizzle rain",
      "drizzle-rain": "Drizzle rain",
      "high-intensity-drizzle-rain": "High intensity drizzle rain",
      "shower-rain-and-drizzle": "Shower rain and drizzle",
      "heavy-shower-rain-and-drizzle": "Heavy shower rain and drizzle",
      "shower-drizzle": "Shower drizzle",
      "light-rain": "Light Rain",
      "moderate-rain": "Moderate Rain",
      "heavy-intensity-rain": "Heavy Intensity Rain",
      "very-heavy-rain": "Very Heavy Rain",
      "extreme-rain": "Extreme Rain",
      "freezing-rain": "Freezing Rain",
      "light-intensity-shower-rain": "Light intensity shower rain",
      "shower-rain": "Shower rain",
      "heavy-intensity-shower-rain": "Heavy intensity shower rain",
      "ragged-shower-rain": "Ragged shower rain",
      "clear-sky": "Clear Sky",
      "few-clouds": "Few Clouds: 11-25%",
      "scattered-clouds": "Scattered Clouds: 25-50%",
      "broken-clouds": "Broken Clouds: 51-84%",
      "overcast-clouds": "Overcast Clouds: 51-84%",
      "android_install_instruction_step_one": "Step 1: Make sure you open the Aeon Calendar in Google Chrome",
      "androind_install_instruction_step_two_one": "Step 2: Tap on the",
      "androind_install_instruction_step_two_two": "button in the top right corner",
      "android_install_instruction_step_three_one": "Step 3: Tap on",
      "android_install_instruction_step_three_two": "Add to Home Screen",
      "android_install_instruction_step_three_three": "or",
      "android_install_instruction_step_three_four": "Install app",
    }
  },
  th: {
    translation: {
      "navbar_install": "ติดตั้ง",
      "tab_lucky_color": "สีมงคล",
      "tab_motivation": "คำคม",
      "tab_zodiac": "ปีนักษัตร",
      "tab_weather": "สภาพอากาศ",
      "calendar_month": "เดือน",
      "calendar_week": "สัปดาห์",
      "calendar_sun": "อา",
      "calendar_mon": "จ",
      "calendar_tue": "อ",
      "calendar_wed": "พ",
      "calendar_thu": "พฤ",
      "calendar_fri": "ศ",
      "calendar_sat": "ส",
      "lucky_color_title": "สีมงคลประจำวัน",
      "lucky_color_auspicious_title": "วันนี้วันธงชัย",
      "lucky_color_money": "การเงิน",
      "lucky_color_work": "การงาน",
      "lucky_color_love": "ความรัก",
      "lucky_color_attribution": "*อ้างอิงข้อมูลจาก อาจารย์เอ๋ รดา เปลี่ยนสี เปลี่ยนชีวิต",
      "motivation_title": "แรงบันดาลใจประจำวัน",
      "zodiac_title": "ปีนักษัตรประจำวัน",
      "zodiac_dont_get_along": "ไม่ถูกกับ",
      "weather_title": "สภาพอากาศวันนี้",
      "weather_chance_of_rain": "มีโอกาสฝนตก {{chance_of_rain}}%",
      "weather_partly_cloudy": "มีเมฆบางส่วน",
      "weather_aqi_good": "อากาศดี ",
      "weather_aqi_good_description": "อากาศดีมาก เหมาะสำหรับกิจกรรมกลางแจ้ง",
      "weather_aqi_moderate": "อากาศดีปานกลาง",
      "weather_aqi_moderate_description": "คนที่มีภูมิคุ้มกันต่ำ ควรลดกิจกรรมกลางแจ้ง",
      "weather_aqi_bad": "สภาพอากาศเริ่มแย่",
      "weather_aqi_bad_description": "มีผลกระทบกับประชาชนทั่วไปและคนที่มีภูมิคุ้มกันต่ำควรงดกิจกรรมกลางแจ้ง ",
      "weather_aqi_unhealthy": "ไม่ปลอดภัยต่อสุขภาพ",
      "weather_aqi_unhealthy_description": "ส่งผลกระทบต่อคนที่มีภูมิคุ้มกันต่ำอย่างมาก รวมถึงบุคคลทั่วไป อาจจะส่งผลต่อระบบทางเดินหายใจ ไม่ควรทำกิจกรรมนอกบ้าน",      
      "weather_aqi_very_unhealthy": "ไม่ปลอดภัยต่อสุขภาพ",
      "weather_aqi_very_unhealthy_description": "ทุกคนมีความเสี่ยงไม่ควรทำกิจกรรมนอกบ้าน",
      "weather_aqi_hazardous": "ไม่ปลอดภัยต่อสุขภาพอย่างมาก",
      "weather_aqi_hazardous_description": "ทุกคนมีความเสี่ยงสูงมากต่อระบบทางเดินหายใจ ควรอยู่ในบ้าน",
      "install_instruction_step_one": "ขั้นตอนที่ 1 เปิด ปฏิทิน Aeon ในเบราว์เซอร์ซาฟารี",
      "install_instruction_step_two_one": "ขั้นตอนที่ 2 กดที่ไอคอน",
      "install_instruction_step_two_two": "ที่แถบด้านล่าง",
      "install_instruction_step_three_one": "ขั้นตอนที่ 3 เลื่อนและแตะที่ตัวเลือก",
      "install_instruction_step_three_two": "",
      "install_instruction_step_four_one": "ขั้นตอนที่ 4 หาปุ่ม",
      "install_instruction_step_four_two": "ที่มุมขวาบนแล้วกด",
      "install_instruction_step_five": "ขั้นตอนที่ 5 แอปพลิเคชั่นจะปรากฏอยู่หน้าจอหลักของคุณ",
      "thunderstorm-with-light-rain": "มีพายุฟ้าคะนองและมีฝนเล็กน้อย",
      "thunderstorm-with-rain": "มีพายุฟ้าคะนองและมีฝนเล็กน้อย",
      "thunderstorm-with-heavy-rain": "มีฝนฟ้าคะนองและมีฝนตกหนัก",
      "light-thunderstorm": "พายุฟ้าคะนองเบาบาง",
      "thunderstorm": "พายุฝนฟ้าคะนอง",
      "ragged-thunderstorm": "พายุฝนฟ้าคะนอง",
      "thunderstorm-with-light-drizzle": "มีพายุฟ้าคะนองและมีฝนตกปรอยๆ",
      "thunderstorm-with-drizzle": "มีฝนฟ้าคะนองและมีฝนตกปรอยๆ",
      "thunderstorm-with-heavy-drizzle": "มีฝนฟ้าคะนองและมีฝนตกปรอยๆ",
      "light-intensity-drizzle": "ฝนตกปรอยๆ",
      "drizzle": "ฝนตกปรอยๆ",
      "heavy-intensity-drizzle": "มีฝนตก",
      "light-intensity-drizzle-rain": "ฝนตกปรอยๆ",
      "drizzle-rain": "ฝนตกปรอยๆ",
      "high-intensity-drizzle-rain": "ฝนตกปรอยๆ",
      "shower-rain-and-drizzle": "ฝนตกปรอยๆ",
      "heavy-shower-rain-and-drizzle": "ฝนตกหนักเป็นบางแห่งและมีฝนตกปรอยๆ",
      "shower-drizzle": "ฝนตกปรอยๆ",
      "light-rain": "ฝนตกปรอยๆ",
      "moderate-rain": "ฝนตกปานกลาง",
      "heavy-intensity-rain": "ฝนตกหนัก",
      "very-heavy-rain": "ฝนตกหนักมาก",
      "extreme-rain": "ฝนตกชุก",
      "freezing-rain": "ฝนตกชุก",
      "light-intensity-shower-rain": "ฝนตกชุก",
      "shower-rain": "ฝนตก",
      "heavy-intensity-shower-rain": "ฝนตกหนัก",
      "ragged-shower-rain": "ฝนตกพรำๆ",
      "clear-sky": "ฟ้าโปร่ง",
      "few-clouds": "เมฆน้อย: 11-25%",
      "scattered-clouds": "เมฆกระจาย: 25-50%",
      "broken-clouds": "เมฆกระจาย: 51-84%",
      "overcast-clouds": "เมฆครึ้ม: 51-84%",
      "android_install_instruction_step_one": "ขั้นตอนที่ 1: เปิดเว็บไซต์บน Google Chrome",
      "androind_install_instruction_step_two_one": "ขั้นตอนที่ 2: กดปุ่ม",
      "androind_install_instruction_step_two_two": "ที่อยู่มุมบนด้านขวาสุด",
      "android_install_instruction_step_three_one": "ขั้นตอนที่ 3: กด",
      "android_install_instruction_step_three_two": "Add to Home Screen",
      "android_install_instruction_step_three_three": "หรือกด",
      "android_install_instruction_step_three_four": "ติดตั้งแอป",
    }
  }
};

export default translations;

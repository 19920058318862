import popup0101 from "../assets/events/popups/0101.gif";
import popup0105 from "../assets/events/popups/0105.gif";
import popup0306 from "../assets/events/popups/0306.jpg";
import popup0405 from "../assets/events/popups/0405.jpg";
import popup0512 from "../assets/events/popups/0512.gif";
import popup0604 from "../assets/events/popups/0604.jpg";
import popup0901 from "../assets/events/popups/0901.gif";
import popup1012 from "../assets/events/popups/1012.gif";
import popup1105 from "../assets/events/popups/1105.gif";
import popup1202 from "../assets/events/popups/1202.gif";
import popup1208 from "../assets/events/popups/1208.gif";
import popup1304 from "../assets/events/popups/1304.gif";
import popup1404 from "../assets/events/popups/1404.gif";
import popup1310 from "../assets/events/popups/1310.jpg";
import popup1402 from "../assets/events/popups/1402.gif";
import popup1601 from "../assets/events/popups/1601.gif";
import popup1911 from "../assets/events/popups/1911.gif";
import popup2310 from "../assets/events/popups/2310.jpg";
import popup2407 from "../assets/events/popups/2407.gif";
import popup2507 from "../assets/events/popups/2507.gif";
import popup2512 from "../assets/events/popups/2512.gif";
import popup2602 from "../assets/events/popups/2602.gif";
import popup2605 from "../assets/events/popups/2605.gif";
import popup2807 from "../assets/events/popups/2807.jpg";
import popup3112 from "../assets/events/popups/3112.gif";

import icon0101 from "../assets/events/icons/0101.svg";
import icon0105 from "../assets/events/icons/0105.svg";
// import icon0306 from "../assets/events/icons/0306.png";
// import icon0405 from "../assets/events/icons/0405.png";
import icon0605 from "../assets/events/icons/0605.svg";
import icon0512 from "../assets/events/icons/0512.svg";
// import icon0604 from "../assets/events/icons/0604.png";
import icon0901 from "../assets/events/icons/0901.svg";
import icon1012 from "../assets/events/icons/1012.svg";
import icon1105 from "../assets/events/icons/1105.svg";
import icon1202 from "../assets/events/icons/1202.svg";
import icon1208 from "../assets/events/icons/1208.svg";
import icon1304 from "../assets/events/icons/1304.svg";
// import icon1310 from "../assets/events/icons/1310.png";
import icon1402 from "../assets/events/icons/1402.svg";
import icon1404 from "../assets/events/icons/1404.svg";
import icon1504 from "../assets/events/icons/1504.svg";
import icon1601 from "../assets/events/icons/1601.svg";
import icon1911 from "../assets/events/icons/1911.svg";
import icon2109 from "../assets/events/icons/2109.svg";
// import icon2310 from "../assets/events/icons/2310.png";
import icon2512 from "../assets/events/icons/2512.svg";
// import icon2807 from "../assets/events/icons/2807.png";
import icon3112 from "../assets/events/icons/3112.svg";
import iconBuddhistHoliday from "../assets/events/icons/buddhistHoliday.svg";

import jpflag from "../assets/events/ext/jp.svg";

const events = [
{
  id: 3,
  date: '2020-12-25',
  label_th: 'วันคริสต์มาส',
  description_th: 'วันประสูติของพระเยซูผู้เป็นศาสดาสูงสุดของศาสนาคริสต์',
  label_en: 'Christmas day',
  description_en: 'The day celebrates Jesus Christ\'s birthday.',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon2512,
  sticker: popup2512,
  thumbnail: popup2512,
},
{
  id: 5,
  date: '2020-12-31',
  label_th: 'วันสิ้นปี',
  description_th: 'วันสุดท้ายตามปฏิทินสุริยคติ',
  label_en: 'New year’s eve',
  description_en: 'The last day of the year',
  color: '',
  holidayType: 'public',
  thumbnail: popup3112,
  sticker: popup3112,
  dayBlockImg: icon3112,
},
{
  id: 6,
  date: '2021-01-01',
  label_th: 'วันขึ้นปีใหม่',
  label_en: 'New year’s day',
  description_th: 'วันเริ่มต้นปีใหม่',
  description_en: 'First day of the year',
  color: '',
  holidayType: 'public',
  thumbnail: popup0101,
  sticker: popup0101,
  dayBlockImg: icon0101,
},
{
  id: 7,
  date: '2021-01-09',
  label_th: 'วันเด็กแห่งชาติ',
  description_th: 'เพื่อตระหนักถึงความสำคัญของเด็กที่จะเติบโตไปเป็นอนาคตของชาติ',
  label_en: 'Children’s day',
  description_en: 'A commemorative date celebrated annually in honor of children',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon0901,
  thumbnail: popup0901,
  sticker: popup0901,
},
{
  id: 8,
  date: '2021-01-16',
  label_th: 'วันครู',
  description_th: 'ระลึกถึงความสำคัญของครู',
  label_en: 'Teacher’s day',
  description_en: 'A special day for the appreciation of teachers',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon1601,
  thumbnail: popup1601,
  sticker: popup1601,
},
{
  id: 9,
  date: '2021-02-12',
  label_th: 'วันตรุษจีน',
  description_th: 'วันขึ้นปีใหม่ตามปฏิทินจีน',
  label_en: 'Chinese new year’s day',
  description_en: '',
  color: '',
  holidayType: 'public',
  thumbnail: popup1202,
  sticker: popup1202,
  dayBlockImg: icon1202,
},
{
  id: 10,
  date: '2021-02-14',
  label_th: 'วันวาเลนไทน์',
  description_th: 'วันแห่งความรัก',
  label_en: 'Valentine’s day',
  description_en: 'The feast of Saint Valentine, festival to celebrate romantic love, friendship and admiration',
  color: '',
  holidayType: 'public',
  thumbnail: popup1402,
  sticker: popup1402,
  dayBlockImg: icon1402,
},
{
  id: 11,
  date: '2021-02-26',
  label_th: 'วันมาฆบูชา',
  description_th: 'วันที่พระพุทธเจ้าทรงแสดงโอวาทปาติโมกข์แก่พระภิกษุจำนวน 1,250รูป',
  label_en: 'Makha Bucha Day',
  description_en: 'The commemoration day of the Buddha gave "Ovadha Patimokkha” (the Fundamental Teaching) to the assembly of his disciples',
  color: '',
  holidayType: 'public',
  thumbnail: popup2602,
  sticker: popup2602,
  dayBlockImg: iconBuddhistHoliday,
},
{
  // description_th is too long
  id: 12,
  date: '2021-04-06',
  label_th: 'วันจักรี',
  description_th: 'วันที่พระบาทสมเด็จพระพุทธยอดฟ้าจุฬาโลกมหาราชเสด็จปราบดาภิเษกขึ้นครองราชสมบัติเป็นพระมหากษัตริย์แห่งราชวงศ์จักรีและทรงสถาปนากรุงเทพมหานครเป็นเมืองหลวงของประเทศไทย',
  label_en: 'Chakri Memorial Day',
  description_en: 'The commemoration day of the establishment of the Chakri Dynasty by Phra Buddha Yodfa Chulaloke ( Rama I) in 1782',
  color: '',
  holidayType: 'royal',
  thumbnail: popup0604,
  sticker: popup0604,
  // dayBlockImg: icon0604,
},
{
  id: 13,
  date: '2021-04-13',
  label_th: 'วันสงกรานต์และวันผู้สูงอายุ',
  description_th: 'วันขึ้นปีใหม่ไทย',
  label_en: 'Songkran Festival Day',
  description_en: 'Thai new year’s day',
  color: '',
  holidayType: 'public',
  thumbnail: popup1304,
  sticker: popup1304,
  dayBlockImg: icon1304,
},
{
  id: 14,
  date: '2021-04-14',
  label_th: 'วันสงกรานต์',
  description_th: 'วันขึ้นปีใหม่ไทย',
  label_en: 'Songkran Festival Day',
  description_en: 'Thai new year’s day',
  color: '',
  holidayType: 'public',
  thumbnail: popup1404,
  sticker: popup1404,
  dayBlockImg: icon1404,
},
{
  id: 15,
  date: '2021-04-15',
  label_th: 'วันสงกรานต์',
  description_th: 'วันขึ้นปีใหม่ไทย',
  label_en: 'Songkran Festival Day',
  description_en: 'Thai new year’s day',
  color: '',
  holidayType: 'public',
  thumbnail: popup1304,
  sticker: popup1304,
  dayBlockImg: icon1504,
},
{
  id: 16,
  date: '2021-05-01',
  label_th: 'วันแรงงานแห่งชาติ',
  description_th: 'วันระลึกถึงผู้ใช้แรงงาน ผู้เป็นกำลังสำคัญของฝ่ายผลิตที่ช่วยขับเคลื่อนระบบเศรษฐกิจ',
  label_en: 'National Labour Day',
  description_en: 'An annual holiday to celebrate the achievements of workers',
  color: '',
  holidayType: 'public',
  thumbnail: popup0105,
  sticker: popup0105,
  dayBlockImg: icon0105,
},
{
  id: 17,
  date: '2021-05-04',
  label_th: 'วันฉัตรมงคล',
  description_th: 'วันขึ้นครองราชสมบัติของพระบาทสมเด็จพระวชิรเกล้าเจ้าอยู่หัว',
  label_en: 'Coronation Day',
  description_en: 'The day His Majesty the King was officially crowned',
  color: '',
  holidayType: 'royal',
  sticker: popup0405,
  thumbnail: popup0405,
  // dayBlockImg: icon0405,
},
{
  id: 18,
  date: '2021-05-11',
  label_th: 'วันพืชมงคล',
  description_th: 'วันที่กำหนดพระราชพิธีจรดพระนังคัลแรกนาขวัญ เพื่อเสริมสร้างให้เกิดขวัญและกำลังใจแก่เกษตรกรของชาติ',
  label_en: 'Royal Ploughing Ceremony Day',
  description_en: 'The day designated for the Ploughing Ceremony. It is an ancient ceremony that boosts the morale of farmers',
  color: '',
  holidayType: 'public',
  thumbnail: popup1105,
  sticker: popup1105,
  dayBlockImg: icon1105,
},
{
  id: 19,
  date: '2021-05-26',
  label_th: 'วันวิสาขบูชา',
  description_th: 'วันสำคัญยิ่งทางพระพุทธศาสนา เป็นวันที่พระพุทธเจ้าประสูติ ตรัสรู้ และปรินิพพาน',
  label_en: 'Visakha Bucha Day',
  description_en: 'The commemoration day of the Buddha’s birth, attainment of Enlightenment and death, coincidentally occurred on the same day and in the same month',
  color: '',
  holidayType: 'public',
  thumbnail: popup2605,
  sticker: popup2605,
  dayBlockImg: iconBuddhistHoliday,
},
{
  // label_th too long
  id: 20,
  date: '2021-06-03',
  label_th: 'วันเฉลิมพระชนมพรรษาสมเด็จพระนางเจ้าสุทิดา พัชรสุธาพิมลลักษณ พระบรมราชินี',
  description_th: 'วันคล้ายวันพระราชสมภพสมเด็จพระนางเจ้าสุทิดา พัชรสุธาพิมลลักษณ พระบรมราชินี',
  label_en: 'H.M.The Queen’s Birthday',
  description_en: 'The birthday of H.M. The Queen',
  color: '',
  holidayType: 'royal',
  thumbnail: popup0306,
  sticker: popup0306,
  // dayBlockImg: icon0306,
},
{
  // label_th too long
  id: 21,
  date: '2021-06-05',
  label_th: 'วันสิ่งแวดล้อมโลก',
  description_th: 'เป็นวันที่องค์การสหประชาชาติก่อตั้งขึ้นเพื่อให้ทุกคนเกิดความตื่นตัวในด้านวิกฤตการณ์สิ่งแวดล้อมขึ้นทั่วโลก',
  label_en: 'World Environment Day',
  description_en: 'Initiative created by United Nation to create environmental awareness',
  color: '',
  holidayType: 'public',
  labelIcon: icon0605,
},
{
  // sticker content format is wrong
  id: 22,
  date: '2021-07-24',
  label_th: 'วันอาสาฬหบูชา',
  description_th: 'วันที่พระพุทธเจ้าทรงแสดงปฐมเทศนา คือ ธัมมจักกัปปวัตนสูตร แก่ปัญจวัคคีย์ทั้ง5',
  label_en: 'Asarnha Bucha Day',
  description_en: 'The commemoration day of the Buddha delivered his first Sermon, or Dhammachakkappavattanasutr, before the first five disciples in Buddhism',
  color: '',
  holidayType: 'public',
  dayBlockImg: iconBuddhistHoliday,
  thumbnail: popup2407,
  sticker: popup2407,
},
{
  // description_th too long
  id: 23,
  date: '2021-07-25',
  label_th: 'วันเข้าพรรษา',
  description_th: 'วันที่พระสงฆ์เถรวาทจะอธิษฐานว่าจะพักประจำอยู่ ณ ที่ใดที่หนึ่ง ตลอดฤดูฝนเป็นระยะเวลา 3 เดือน ตามที่พระธรรมวินัยบัญญัติไว้ โดยไม่ไปค้างแรมที่อื่น',
  label_en: 'Buddhist Lent Day',
  description_en: 'The day on which the Buddhist monks take the vow to stay only at one particular place or temple during the rainy season',
  color: '',
  holidayType: 'public',
  dayBlockImg: iconBuddhistHoliday,
  thumbnail: popup2507,
  sticker: popup2507,
},
{
  // label_th too long
  id: 21,
  date: '2021-07-28',
  label_th: 'วันเฉลิมพระชนมพรรษาพระบาทสมเด็จพระวชิรเกล้าเจ้าอยู่หัว',
  description_th: 'วันคล้ายวันพระราชสมภพพระบาทสมเด็จพระวชิรเกล้าเจ้าอยู่หัว',
  label_en: 'H.M. The King’s Birthday',
  description_en: 'The birthday of H.M. The King',
  color: '',
  holidayType: 'royal',
  thumbnail: popup2807,
  sticker: popup2807,
  // dayBlockImg: icon2807,
},
{
  // description_th too long
  id: 24,
  date: '2021-08-12',
  label_th: 'วันแม่แห่งชาติ',
  description_th: 'วันคล้ายวันพระราชสมภพสมเด็จพระนางเจ้าสิริกิติ์ พระบรมราชินีนาถ พระบรมราชชนนีพันปีหลวง และเพื่อระลึกถึงความสำคัญของแม่',
  label_en: 'Mother’s day H.M.Queen Mother’s day',
  description_en: 'The birthday of H.M. Queen Mother, Queen Sirikit. The day of honoring motherhood and maternal bonds',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon1208,
  thumbnail: popup1208,
  sticker: popup1208,
},
{
  // description_th too long
  id: 24,
  date: '2021-09-21',
  label_th: 'วันสันติภาพโลก',
  description_th: 'เพื่อตระหนักถึงความสำคัญและงดใช้ความรุนแรง หยุดทำสงคราม และร่วมมือกันสร้างสันติภาพให้เกิดขึ้นทั่วโลก',
  label_en: 'World Peace Day',
  description_en: 'This is a day devoted to strengthening the ideals of peace,  through of non-violence',
  color: '',
  holidayType: 'public',
  labelIcon: icon2109,
  // thumbnail: mthsgif,
  // sticker: popup2109,
},
{
  // label_th too long
  id: 25,
  date: '2021-10-13',
  label_th: 'วันคล้ายวันสวรรคตพระบาทสมเด็จพระบรมชนกาธิเบศร มหาภูมิพลอดุลยเดชมหาราช บรมนาถบพิตร',
  description_th: '',
  label_en: 'H.M. King Bhumibol Adulyadej The Great Memorial Day',
  description_en: 'It is devoted to strengthening the ideals of peace by observing non-violence ',
  color: '',
  holidayType: 'royal',
  thumbnail: popup1310,
  sticker: popup1310,
  // dayBlockImg: icon1310,
},
{
  id: 26,
  date: '2021-10-23',
  label_th: 'วันปิยมหาราช',
  description_th: 'วันคล้ายวันสวรรคตพระบาทสมเด็จพระจุลจอมเกล้าเจ้าอยู่หัว',
  label_en: 'King Chulalongkorn Memorial Day',
  description_en: 'The memorial day of the passing away of King Chulalongkorn',
  color: '',
  holidayType: 'royal',
  // dayBlockImg: icon2310,
  sticker: popup2310,
  thumbnail: popup2310,
},
{
  id: 26.1,
  date: '2021-11-19',
  label_th: 'วันลอยกระทง',
  description_th: 'ขอขมาและระลึกถึงพระแม่คงคา',
  label_en: 'Loy Krathong Day',
  description_en: 'A day to thank the Goddess of Water also known as Phra Mae Khongkha.',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon1911,
  sticker: popup1911,
  thumbnail: popup1911,
},
{
  id: 27,
  date: '2021-12-05',
  label_th: 'วันพ่อแห่งชาติ',
  description_th: 'วันคล้ายวันพระบรมราชสมภพ พระบาทสมเด็จพระบรมชนกาธิเบศร มหาภูมิพลอดุลยเดชมหาราช บรมนาถบพิตร และระลึกถึงความสำคัญของพ่อ',
  label_en: 'Father’s day H.M. King Bhumibol Adulyadej The Great’s Birthday',
  description_en: 'The birthday of H.M. King Bhumibol Adulyadej. The day of honoring fatherhood and paternal bonds',
  color: '',
  holidayType: 'public',
  sticker: popup0512,
  thumbnail: popup0512,
  dayBlockImg: icon0512,
},
{
  id: 28,
  date: '2021-12-10',
  label_th: 'วันรัฐธรรมนูญ',
  description_th: 'วันประกาศใช้รัฐธรรมนูญฉบับแรกในประเทศไทย',
  label_en: 'Constitution Day',
  description_en: 'The day to remember the adoption of Thailand\'s first permanent constitution',
  color: '',
  holidayType: 'public',
  sticker: popup1012,
  thumbnail: popup1012,
  dayBlockImg: icon1012,
},
{
  id: 29,
  date: '2021-12-25',
  label_th: 'วันคริสต์มาส',
  description_th: 'วันประสูติของพระเยซูผู้เป็นศาสดาสูงสุดของศาสนาคริสต์',
  label_en: 'Christmas day',
  description_en: 'The day celebrates Jesus Christ\'s birthday',
  color: '',
  holidayType: 'public',
  dayBlockImg: icon2512,
  thumbnail: popup2512,
  sticker: popup2512,
},
{
  id: 30,
  date: '2021-12-31',
  label_th: 'วันสิ้นปี',
  description_th: 'วันสุดท้ายตามปฏิทินสุริยคติ',
  label_en: 'New year’s eve',
  description_en: 'The last day of the year',
  color: '',
  holidayType: 'public',
  thumbnail: popup3112,
  sticker: popup3112,
  dayBlockImg: icon3112,
},
{
  id: 31,
  date: '2021-01-11',
  label_th: 'วันผู้ใหญ่ วันบรรลุนิติภาวะ',
  description_th: 'วันสำคัญของการก้าวย่างสู่ความเป็นผู้ใหญ่',
  label_en: 'Coming of Age Day',
  description_en: 'The day to congratulate and encourage people who have reached the age of maturity',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 32,
  date: '2021-02-11',
  label_th: 'วันที่ระลึกการตั้งประเทศ',
  description_th: 'วันที่ระลึกการตั้งประเทศ',
  label_en: 'National Foundation Day',
  description_en: 'The commemoration day on the establishment of the nation',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 33,
  date: '2021-02-23',
  label_th: 'พระราชสมภพ',
  description_th: 'วันเฉลิมพระชนมพรรษา องค์สมเด็จพระจักรพรรดิ์',
  label_en: 'Emperor’s Birthday',
  description_en: 'The current emperor’s birthday',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 34,
  date: '2021-03-20',
  label_th: 'วันชุนบุน',
  description_th: 'วันเริ่มต้นฤดูใบไม้ผลิ',
  label_en: 'Vernal Equinox Day',
  description_en: 'The day for the admiration of nature and the love of living things',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 35,
  date: '2021-04-29',
  label_th: 'วันโชวะ (GW) GW = โกลเด้นวีค',
  description_th: 'วันเฉลิมฉลองการประสูติของจักรพรรดิโชวะ',
  label_en: 'Shōwa Day',
  description_en: 'The birthday of former Emperor Showa',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 36,
  date: '2021-05-03',
  label_th: 'วันที่ระลึกรัฐธรรมนูญ',
  description_th: 'วันหยุดเพื่อเป็นการระลึกถึงการประกาศใช้รัฐธรรมนูญ',
  label_en: 'Constitution Memorial Day',
  description_en: 'The commemoration day on which Japan\'s postwar constitution took effect',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 37,
  date: '2021-05-04',
  label_th: 'วันสีเขียว',
  description_th: 'วันที่ทุกคนจะมาแสดงความขอบคุณให้กับธรรมชาติ',
  label_en: 'Greenery Day',
  description_en: 'The day to commune with nature and be grateful for its blessings',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 38,
  date: '2021-05-05',
  label_th: 'วันเด็ก',
  description_th: 'วันที่มีพิธีปัดเป่าความชั่วร้าย อธิษฐานให้เด็กๆมีสุขภาพที่แข็งแรง',
  label_en: 'Children’s Day',
  description_en: 'Day on which to esteem the personalities of children',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 39,
  date: '2021-07-22',
  label_th: 'วันแห่งท้องทะเล',
  description_th: 'วันหยุดเพื่อที่จะแสดงความเคารพแก่ทะเลอันอุดมสมบูรณ์',
  label_en: 'Marine Day',
  description_en: 'The day of gratitude for the blessings of the oceans and for hoping for the prosperity',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 40,
  date: '2021-08-11',
  label_th: 'วันแห่งภูผา',
  description_th: 'วันที่แสดงความเคารพและขอบคุณแก่ภูเขา',
  label_en: 'Mountain Day',
  description_en: 'The day on which to appreciate mountains and all things mountain-related',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 41,
  date: '2021-09-20',
  label_th: 'วันผู้สูงอายุ',
  description_th: 'วันแสดงความเคารพแก่ผู้สูงอายุและเป็นวันที่อวยพรขอให้ท่านมีอายุยืน',
  label_en: 'Respect for the Aged Day',
  description_en: 'The day to respect the elderly and celebrate long life',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 42,
  date: '2021-09-23',
  label_th: 'วันเริ่มต้นฤดูใบไม้ร่วง',
  description_th: 'วันเริ่มต้นฤดูใบไม้ร่วง',
  label_en: 'Autumn Equinox',
  description_en: 'The day on which to honor one\'s ancestors and remember the dead',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 44,
  date: '2021-10-11',
  label_th: 'วันแห่งกีฬาและสุขภาพ',
  description_th: 'วันที่มีเพื่อระลึกความสำคัญของสุขภาพและการออกกำลังกาย',
  label_en: 'Sports and Health Day',
  description_en: 'The day on which to enjoy sports and cultivate a healthy mind and body',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 45,
  date: '2021-11-03',
  label_th: 'วันวัฒนธรรมแห่งชาติ',
  description_th: 'วันที่เฉลิมฉลองให้กับการพัฒนาวัฒนธรรมต่างๆความเป็นอิสระและสันติสุข',
  label_en: 'Culture Day',
  description_en: 'The day to celebrate peace and freedom and promote culture',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 46,
  date: '2021-11-23',
  label_th: 'วันขอบคุณผู้ใช้แรงงาน',
  description_th: 'วันที่จะแสดงความขอบคุณแก่ผู้ที่ทำงานทั้งหลาย',
  label_en: 'Labor Thanksgiving Day',
  description_en: 'Celebrating production and giving one another thanks',
  color: '',
  holidayType: 'jp',
  eventIndicator: jpflag,
},
{
  id: 47,
  date: '2021-05-03',
  label_th: '(วันหยุดชดเชยวันแรงงาน)',
  description_th: '',
  label_en: '(Substitution for National Labour Day)',
  description_en: '',
  color: '',
  holidayType: 'public',
},
{
  id: 48,
  date: '2021-07-26',
  label_th: '(วันหยุดชดเชยวันอาสาฬหบูชา)',
  description_th: '',
  label_en: '(Substitution for Asaranha Bucha Day)',
  description_en: '',
  color: '',
  holidayType: 'public',
},
{
  id: 49,
  date: '2021-10-25',
  label_th: '(วันหยุดชดเชยวันปิยมหาราช)',
  description_th: '',
  label_en: '(Substitution for King Chulalongkorn Memorial Day)',
  description_en: '',
  color: '',
  holidayType: 'public',
},
{
  id: 50,
  date: '2021-12-06',
  label_th: '(วันหยุดชดเชยวันพ่อ)',
  description_th: '',
  label_en: '(Substitution for Father\'s day)',
  description_en: '',
  color: '',
  holidayType: 'public',
}];

export default events;
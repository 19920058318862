const auspiciousDays = [
  '07-01-2021',
  '14-01-2021',
  '21-01-2021',
  '28-01-2021',
  '11-02-2021',
  '04-02-2021',
  '18-02-2021',
  '25-02-2021',
  '11-03-2021',
  '04-03-2021',
  '18-03-2021',
  '25-03-2021',
  '01-04-2021',
  '08-04-2021',
  '15-04-2021',
  '18-04-2021',
  '25-04-2021',
  '02-05-2021',
  '09-05-2021',
  '16-05-2021',
  '23-05-2021',
  '30-05-2021',
  '06-06-2021',
  '13-06-2021',
  '20-06-2021',
  '27-06-2021',
  '04-07-2021',
  '11-07-2021',
  '18-07-2021',
  '25-07-2021',
  '01-08-2021',
  '08-08-2021',
  '15-08-2021',
  '22-08-2021',
  '29-08-2021',
  '05-09-2021',
  '12-09-2021',
  '19-09-2021',
  '26-09-2021',
  '03-10-2021',
  '10-10-2021',
  '17-10-2021',
  '24-10-2021',
  '31-10-2021',
  '07-11-2021',
  '14-11-2021',
  '21-11-2021',
  '28-11-2021',
  '05-12-2021',
  '12-12-2021',
  '19-12-2021',
  '26-12-2021',
];

export default auspiciousDays;
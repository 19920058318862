const prayDays = [
  '06-01-2021',
  '13-01-2021',
  '21-01-2021',
  '28-01-2021',
  '05-02-2021',
  '11-02-2021',
  '19-02-2021',
  '06-03-2021',
  '13-03-2021',
  '21-03-2021',
  '28-03-2021',
  '05-04-2021',
  '11-04-2021',
  '19-04-2021',
  '26-04-2021',
  '04-05-2021',
  '11-05-2021',
  '19-05-2021',
  '03-06-2021',
  '09-06-2021',
  '17-06-2021',
  '24-06-2021',
  '02-07-2021',
  '09-07-2021',
  '17-07-2021',
  '01-08-2021',
  '08-08-2021',
  '16-08-2021',
  '23-08-2021',
  '31-08-2021',
  '06-09-2021',
  '14-09-2021',
  '29-09-2021',
  '06-10-2021',
  '14-10-2021',
  '21-10-2021',
  '29-10-2021',
  '04-11-2021',
  '12-11-2021',
  '19-11-2021',
  '27-11-2021',
  '04-12-2021',
  '12-12-2021',
  '19-12-2021',
  '27-12-2021',
];

export default prayDays;
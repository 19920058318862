import React from 'react';

import Block from '../Block';

import { ReactComponent as Horse } from '../../assets/animals/horse.svg';
import { ReactComponent as Dog } from '../../assets/animals/dog.svg';
import { ReactComponent as Dragon } from '../../assets/animals/dragon.svg';
import { ReactComponent as Goat } from '../../assets/animals/goat.svg';
import { ReactComponent as Hen } from '../../assets/animals/hen.svg';
import { ReactComponent as Monkey } from '../../assets/animals/monkey.svg';
import { ReactComponent as Mouse } from '../../assets/animals/mouse.svg';
import { ReactComponent as Pig } from '../../assets/animals/pig.svg';
import { ReactComponent as Rabbit } from '../../assets/animals/rabbit.svg';
import { ReactComponent as Snake } from '../../assets/animals/snake.svg';
import { ReactComponent as Tiger } from '../../assets/animals/tiger.svg';
import { ReactComponent as Cow } from '../../assets/animals/cow.svg';

import styles from './AnimalBlock.module.css';

const animals = {
  horse: {icon: Horse, label_th: 'ปีม้า', label_en: 'Year of the Horse'},
  rat: {icon: Mouse, label_th: 'ปีหนู', label_en: 'Year of the Rat'},
  goat: {icon: Goat, label_th: 'ปีแพะ', label_en: 'Year of the Goat'},
  cow: {icon: Cow, label_th: 'ปีวัว', label_en: 'Year of the Cow'},
  monkey: {icon: Monkey, label_th: 'ปีลิง', label_en: 'Year of the Monkey'},
  tiger: {icon: Tiger, label_th: 'ปีเสือ', label_en: 'Year of the Tiger'},
  chicken: {icon: Hen, label_th: 'ปีไก่', label_en: 'Year of the Rooster'},
  rabbit: {icon: Rabbit, label_th: 'ปีกระต่าย', label_en: 'Year of the Rabbit'},
  dog: {icon: Dog, label_th: 'ปีหมา', label_en: 'Year of the Dog'},
  dragon: {icon: Dragon, label_th: 'ปีงูใหญ่', label_en: 'Year of the Dragon'},
  pig: {icon: Pig, label_th: 'ปีหมู', label_en: 'Year of the Pig'},
  snake: {icon: Snake, label_th: 'ปีงูเล็ก', label_en: 'Year of the Snake'}
};

const heroAnimals = ['chicken', 'dog', 'pig', 'rat', 'cow', 'tiger', 'rabbit', 'dragon', 'snake', 'horse', 'goat', 'monkey'];
const villainAnimals = ['rabbit', 'dragon', 'snake', 'horse', 'goat', 'monkey', 'chicken', 'dog', 'pig', 'rat', 'cow', 'tiger'];

const getAnimalPairForDate = (date) => {
  const daysInYear = date.ordinal;
  const index = daysInYear % heroAnimals.length;

  console.log(daysInYear, index);

  const hero = animals[heroAnimals[index]];
  const villain = animals[villainAnimals[index]];
  return {
    hero,
    villain,
  };
};


const AnimalBlock = ({ date, t, lang }) => {
  const pair = getAnimalPairForDate(date);  

  console.log(pair);
  const HeroIcon = pair.hero.icon;
  const VillainIcon = pair.villain.icon;

  let heroLabel = pair.hero.label_th;
  let villainLabel = pair.villain.label_th;

  if (lang === 'en') {
    heroLabel = pair.hero.label_en;
    villainLabel = pair.villain.label_en;
  }
  return (
    <Block>
      <div className={`columns is-mobile`}>    
        <div className={`column`} style={{color: 'white'}}>
          <p className="subtitle is-4" style={{color: 'inherit'}}>{t('zodiac_title')}</p>
        </div>          
      </div>
      <div className="columns">
        <table className={`table is-fullwidth ${styles.animalTable}`}>
          <tbody>
            <tr>
              <td className={`has-text-centered title is-2 ${styles.tableItem}`}>
                <span role="img" aria-label={heroLabel}><HeroIcon width="48px" height="48px" /></span>
              </td>
              <td className={`has-text-centered title is-2 ${styles.tableItem}`}>
                |
              </td>
              <td className={`has-text-centered title is-2 ${styles.tableItem}`}>
                <span role="img" aria-label={villainLabel}><VillainIcon width="48px" height="48px" /></span>
              </td>
            </tr>
            <tr>
              <td className={`has-text-centered title is-5 ${styles.tableItem}`}>
                {heroLabel}
              </td>
              <td className={`has-text-centered title is-5 ${styles.tableItem}`}>
                {t('zodiac_dont_get_along')}
              </td>
              <td className={`has-text-centered title is-5 ${styles.tableItem}`}>
                {villainLabel}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Block>
  );
}

export default AnimalBlock;
